import { useMutation, useQueryClient } from "@tanstack/react-query";
import { decommaizeNumber } from "@toss/utils";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { CommonFacilityModel } from "src/api/building/building-types";
import {
  AvailableReservationTime,
  mutationAvailableReservationTimeAsync,
  mutationReservationPolicyModeAsync,
  ReservationPolicyMode,
} from "src/api/product/product-api";
import { BaseButton, BaseCheckbox, BaseInput, BaseRadio, BaseTooltip } from "src/components";
import useGetAvailableReservationTime from "src/hooks/product/useGetAvailableReservationTime";
import { SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { axiosInstance, commaizePrice, deleteCommas } from "src/utils";
import { convertMinutesToDHMS } from "../components/ReservationPolicyView";
import { useToast } from "src/recoil/toast/hook";
import _ from "lodash";

export interface ReservationProps {
  spaceType: SpaceTypeT;
  serviceType: UnionServiceType;
  serviceId: string;

  isDefault: boolean; // 기본설정 or 개별설정 호출 방식 결정
  reservationPolicyModeList?: ReservationPolicyMode[]; // 개별설정 팝업에서 사용
  space?: CommonFacilityModel;
  isRemovable?: boolean;
  isEditable?: boolean;
  isChangeValue?: boolean;
  setIsChangeValue?: React.Dispatch<React.SetStateAction<boolean>>;
  [key: string]: any;
}

interface AvailableReservationTimeForm {
  isLimitedAvailableReservationTime: boolean; // 예약가능시간대제한여부

  isAvailableReservationTimeStart: boolean;
  isAvailableReservationTimeEnd: boolean;

  availableReservationTimeStart: string; // 예약가능시간대시작 (단위: 분)
  availableReservationTimeEnd: string; // 예약가능시간대종료

  isPolicyMode?: boolean;
}

const defaultValues = {
  isLimitedAvailableReservationTime: false,

  isAvailableReservationTimeStart: false,
  isAvailableReservationTimeEnd: false,

  availableReservationTimeStart: "", // 예약가능시간대시작 (단위: 분)
  availableReservationTimeEnd: "", // 예약가능시간대종료
};

const ReservationAvailabilityPeriod = ({
  spaceType,
  serviceType,
  serviceId,
  isDefault,
  reservationPolicyModeList,
  space,
  isEditable,
  isChangeValue,
  setIsChangeValue,
}: ReservationProps) => {
  const { setBaseModal, setAbstractModalZ1 } = useModal();
  const queryClient = useQueryClient();
  const { openToast } = useToast();

  const { mutateAsync: mutationReservationPolicyMode } = useMutation({
    mutationFn: (data: ReservationPolicyMode[]) => mutationReservationPolicyModeAsync(axiosInstance, data),
    onSuccess: (data) => {
      console.log("mutationReservationPolicyModeAsync data :>> ", data);
      queryClient.invalidateQueries({
        queryKey: ["getReservationPolicyModeAsync"],
      });
    },
  });

  const renderText = (isLimit: boolean, time: string) => {
    if (isLimit) {
      return time === "" || time === "0" ? "분 (0시간 0분)" : `분 (${convertMinutesToDHMS(deleteCommas(time))})`;
    } else {
      return;
    }
  };
  const { data: availableReservationTimeList } = useGetAvailableReservationTime({
    AvailableReservationTimeListParams: {
      isDefault: isDefault,
      serviceId,
      serviceType,
      commonFacilityType: spaceType,
      ...(space?.id && {
        buildingCommonFacilityId: String(space?.id),
      }),
    },
  });

  const isRegisterMode = !availableReservationTimeList?.[0]?.id;

  const { mutate } = useMutation({
    mutationFn: (data: AvailableReservationTime[]) => mutationAvailableReservationTimeAsync(axiosInstance, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getAvailableReservationTimeAsync"],
      });
    },
  });

  const reservationPolicyMode = reservationPolicyModeList?.find(
    (data) => String(data.buildingCommonFacilityId) === String(space?.id) && data.reservationPolicyModeType === "RESERVATION_POLICY_MODE_AVAILABLE",
  );

  // props isDefault가 false 인개별 설정 페이지에서 reservationPolicyMode의 isDefault 값으로 PolicyMode 값을 사용함(null 또는 undefined 면 true로 전환)
  const [isPolicyMode, setIsPolicyMode] = useState(!isDefault ? reservationPolicyMode?.isDefault !== false : false);

  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    register,
    unregister,
    watch,
    trigger,
    control,
    formState: { errors, dirtyFields, isDirty },
  } = useForm<AvailableReservationTimeForm>({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    const defaultSettingData = availableReservationTimeList?.[0];

    if (defaultSettingData) {
      //서버에서 내려온 데이터가 있으면(수정모드일때)
      reset({
        isLimitedAvailableReservationTime: defaultSettingData?.isLimitedAvailableReservationTime,

        //인풋에 들어갈 값이 있으면 true 없으면 false
        isAvailableReservationTimeStart: !!commaizePrice(defaultSettingData?.availableReservationTimeStart),
        isAvailableReservationTimeEnd: !!commaizePrice(defaultSettingData?.availableReservationTimeEnd),

        availableReservationTimeStart: commaizePrice(defaultSettingData?.availableReservationTimeStart), // 예약가능시간대시작 (단위: 분)
        availableReservationTimeEnd: commaizePrice(defaultSettingData?.availableReservationTimeEnd), // 예약가능시간대종료
      });
    }
  }, [availableReservationTimeList, reset]);

  const onValid = async (data: AvailableReservationTimeForm) => {
    if (!isDefault) {
      //개별설정일때
      await mutationReservationPolicyMode([
        {
          serviceId: Number(serviceId), // 서비스ID
          serviceType: serviceType, // 서비스타입
          commonFacilityType: spaceType, // 공용공간타입
          isDefault: isPolicyMode, // 기본설정여부

          buildingCommonFacilityId: Number(space?.id), // 건물-공용공간 ID (isDefault=false인 경우 필수)

          reservationPolicyModeType: "RESERVATION_POLICY_MODE_AVAILABLE",

          cmdType: reservationPolicyMode?.id ? "U" : "C", // 요청타입
          ...(reservationPolicyMode?.id && {
            // 수정모드일때 id 등록
            id: reservationPolicyMode?.id, // 예약가능시간대 ID
          }),
        },
      ]);
    }

    const sendData: AvailableReservationTime[] = [
      {
        serviceId: Number(serviceId), // 서비스ID
        serviceType: serviceType, // 서비스타입
        commonFacilityType: spaceType, // 공용공간타입
        isDefault: isDefault, // 기본설정여부

        isLimitedAvailableReservationTime: data.isLimitedAvailableReservationTime, // 예약가능시간대제한여부
        availableReservationTimeStart: data.availableReservationTimeStart === "" ? -1 : decommaizeNumber(data.availableReservationTimeStart!), // 예약가능시간대시작 (단위: 분)
        availableReservationTimeEnd: data.availableReservationTimeEnd === "" ? -1 : decommaizeNumber(data.availableReservationTimeEnd!), // 예약가능시간대종료
        cmdType: isRegisterMode ? "C" : "U", // 요청타입
        buildingCommonFacilityId: Number(space?.id), // 건물-공용공간 ID (isDefault=false인 경우 필수)

        ...(!isRegisterMode && {
          // 수정모드일때 id 등록
          id: availableReservationTimeList?.[0]?.id, // 예약가능시간대 ID
        }),
      },
    ];

    console.log("Available sendData :>> ", sendData);

    mutate(sendData, {
      onSuccess(data, variables, context) {
        console.log("onSuccess data :>> ", data);
        setBaseModal({ isOpen: false });
        openToast({ content: "저장 되었습니다.", isSystemToast: true });
      },
    });
  };

  //  유효성 확인 후 저장 진행
  const formValidation = () => {
    if (watch("isLimitedAvailableReservationTime") === true && isPolicyMode === false) {
      //제한이면서 개별설정으로 선택시 벨리데이션 체크함
      //무제한과 기본설정에서는 벨리데이션 안함

      register(`availableReservationTimeStart`, {
        validate: (value) => {
          if (value) {
            const num = decommaizeNumber(value) % 60;
            if (num % 30 !== 0) {
              return "30분 단위로 입력해주세요";
            }
          }
          return true;
        },
      });

      register(`availableReservationTimeEnd`, {
        validate: (value) => {
          if (value && watch("availableReservationTimeStart")) {
            if (decommaizeNumber(value) <= decommaizeNumber(watch("availableReservationTimeStart"))) {
              return "시작 시간과 동일하거나 시작 시간보다 낮게 입력할 수 없습니다.";
            }
          }

          if (value) {
            const num = decommaizeNumber(value) % 60;
            if (num % 30 !== 0) {
              return "30분 단위로 입력해주세요";
            }
          }

          return true;
        },
      });

      register(`isAvailableReservationTimeStart`, {
        validate: (value) => {
          if (value === false && watch("isAvailableReservationTimeEnd") === false) {
            return "예약 가능 시작 시간 또는 예약 가능 종료 시간 중 하나를 반드시 선택해주세요";
          }
          if (value === true && watch("availableReservationTimeStart") === "") {
            return "시간을 입력해 주세요.";
          }

          return true;
        },
      });

      register(`isAvailableReservationTimeEnd`, {
        validate: (value) => {
          if (value === false && watch("isAvailableReservationTimeStart") === false) {
            return "예약 가능 시작 시간 또는 예약 가능 종료 시간 중 하나를 반드시 선택해주세요";
          }
          if (value === true && watch("availableReservationTimeEnd") === "") {
            return "시간을 입력해 주세요.";
          }
          return true;
        },
      });
    } else {
      register("availableReservationTimeStart", {
        validate: () => true,
      });
      register("availableReservationTimeEnd", {
        validate: () => true,
      });
      register("isAvailableReservationTimeStart", {
        validate: () => true,
      });
      register("isAvailableReservationTimeEnd", {
        validate: () => true,
      });
    }
  };
  useEffect(() => {
    console.log("isDirty, dirtyFields ", isDirty, dirtyFields);
    if (isDirty && dirtyFields) {
      if (setIsChangeValue) {
        setIsChangeValue(true);
      }
    }
  }, [isDirty, dirtyFields, setIsChangeValue]);

  formValidation();

  return (
    <>
      <section className="base-abstract-modal__contents px30">
        <form onSubmit={handleSubmit(onValid)}>
          {!isDefault && (
            <>
              <div className="base-abstract-modal__contents-subtitle">
                <span className="required font18 font-weight-semibold">예약 정책 적용 방식</span>
              </div>
              <div className="flex-center h36 pt10">
                <BaseRadio
                  id={"isPolicyMode_true"}
                  name={"isPolicyMode"}
                  checked={isPolicyMode === true}
                  label="기본설정"
                  onChange={() => {
                    setIsPolicyMode(true);
                    if (setIsChangeValue) {
                      setIsChangeValue(true);
                    }
                  }}
                />
                <BaseTooltip
                  className={"mr10 no-wrap"}
                  tooltip={
                    (
                      <div>
                        <div>• 예약 기본 정책 설정을 그대로 따릅니다.</div>
                        <div>• 기본 설정이 변경되면 즉시 적용됩니다.</div>
                      </div>
                    ) as any as string
                  }
                  touchIcon="QUESTION"
                ></BaseTooltip>
                <BaseRadio
                  id={"isPolicyMode_false"}
                  name={"isPolicyMode"}
                  checked={isPolicyMode === false}
                  label="개별설정" // 무제한
                  onChange={() => {
                    setIsPolicyMode(false);
                    if (setIsChangeValue) {
                      setIsChangeValue(true);
                    }
                  }}
                />
                <BaseTooltip
                  tooltip={
                    (
                      <div>
                        <div>• 예약 기본 정책 설정을 따르지 않고 이 공용 공간에만 적용되는 별도의 예약 정책을 설정합니다.</div>
                        <div>• 기본 설정이 변경되더라도 영향을 받지 않습니다.</div>
                      </div>
                    ) as any as string
                  }
                  touchIcon="QUESTION"
                ></BaseTooltip>
              </div>
            </>
          )}

          <div className="base-abstract-modal__contents-subtitle">
            <span className="font18 font-weight-semibold">예약 가능 기간 제한</span>
          </div>
          <div className="flex-column d-flex">
            <span className="font14 text-primary3 pb10">
              • 현재 시점부터 선택 가능한 예약 기간을 제한할 수 있습니다. (예: 2시간 이후부터 48시간 이내 예약 가능)
            </span>
            <span className="font14 text-primary3">• 제한 시간은 30분 단위로 입력 가능합니다.</span>

            <Controller
              control={control}
              name={"isLimitedAvailableReservationTime"}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <div className="flex-center h36 pt10">
                  <BaseRadio
                    id={"isLimitedAvailableReservationTime_false"}
                    name={name}
                    checked={
                      //개별설정 && 첫 저장전(빈배열) 이면 false 아니면 값에 따라 결정
                      isPolicyMode && Array.isArray(availableReservationTimeList) && availableReservationTimeList.length === 0
                        ? false
                        : value === false
                    }
                    label="무제한" // 무제한
                    onChange={() => {
                      onChange(false);
                    }} // send value to hook form
                    className={"mr10"}
                    disabled={isPolicyMode !== false}
                  />
                  <BaseRadio
                    id={"isLimitedAvailableReservationTime_true"}
                    name={name}
                    checked={value === true}
                    label="제한"
                    disabled={isPolicyMode !== false}
                    onChange={() => {
                      onChange(true);

                      //첫 설정시 사용함 체크
                      if (isRegisterMode) {
                        setValue("isAvailableReservationTimeStart", true, {
                          shouldDirty: true,
                        });
                        setValue("isAvailableReservationTimeEnd", true, {
                          shouldDirty: true,
                        });
                      }
                    }} // send value to hook form
                  />
                </div>
              )}
            ></Controller>
          </div>

          <div className="base-abstract-modal__contents-subtitle">
            <span className="font18 font-weight-semibold">예약 가능 시작 시간</span>
          </div>
          <div className="flex-column d-flex">
            <span className="font14 text-primary3">• 현재 시점으로부터 설정한 시간 이후에 예약을 할 수 있습니다.</span>
            <div className="flex-center pt10">
              <Controller
                control={control}
                name={"isAvailableReservationTimeStart"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <BaseCheckbox
                    className="w-fit mr10"
                    id={name}
                    name={name}
                    checked={value}
                    onChange={(checked) => {
                      onChange(checked);
                      setValue("availableReservationTimeStart", "", { shouldDirty: true });
                    }}
                    label="적용함"
                    disabled={watch("isLimitedAvailableReservationTime") === false || isPolicyMode !== false}
                  />
                )}
              ></Controller>

              <Controller
                control={control}
                name={"availableReservationTimeStart"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <div className="minmax240">
                    <BaseInput
                      //2,147,483,647
                      type="text"
                      value={value}
                      maxLength={7}
                      onChange={(text: string) => {
                        onChange(commaizePrice(text));
                      }}
                      disabled={
                        watch("isLimitedAvailableReservationTime") === false ||
                        watch("isAvailableReservationTimeStart") === false ||
                        isPolicyMode !== false
                      }
                    />
                  </div>
                )}
              ></Controller>
              <span className="font18 ml10">{renderText(true, watch("availableReservationTimeStart") ?? "") ?? ""} 이후</span>
            </div>

            {errors.isAvailableReservationTimeStart?.message && (
              <p className="validation-text">{errors.isAvailableReservationTimeStart?.message ?? ""}</p>
            )}
            {errors.availableReservationTimeStart?.message && (
              <p className="validation-text">{errors.availableReservationTimeStart?.message ?? ""}</p>
            )}
          </div>
          <div className="base-abstract-modal__contents-subtitle">
            <span className="font18 font-weight-semibold">예약 가능 종료 시간</span>
          </div>
          <div className="flex-column d-flex">
            <span className="font14 text-primary3">• 현재 시점으로부터 설정한 시간 이내에 예약을 할 수 있습니다.</span>
            <div className="flex-center pt10">
              <Controller
                control={control}
                name={"isAvailableReservationTimeEnd"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <BaseCheckbox
                    className="w-fit mr10"
                    id={name}
                    name={name}
                    checked={value}
                    onChange={(checked) => {
                      onChange(checked);
                      setValue("availableReservationTimeEnd", "", { shouldDirty: true });
                    }}
                    label="적용함"
                    disabled={watch("isLimitedAvailableReservationTime") === false || isPolicyMode !== false}
                  />
                )}
              ></Controller>

              <Controller
                control={control}
                name={"availableReservationTimeEnd"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <div className="minmax240">
                    <BaseInput
                      //2,147,483,647
                      type="text"
                      value={value}
                      maxLength={7}
                      onChange={(text: string) => {
                        onChange(commaizePrice(text));
                      }}
                      disabled={
                        watch("isLimitedAvailableReservationTime") === false ||
                        watch("isAvailableReservationTimeEnd") === false ||
                        isPolicyMode !== false
                      }
                    />
                  </div>
                )}
              ></Controller>
              <span className="font18 ml10">{renderText(true, watch("availableReservationTimeEnd") ?? "") ?? ""} 이내</span>
            </div>
            {errors.isAvailableReservationTimeEnd?.message && (
              <p className="validation-text">{errors.isAvailableReservationTimeEnd?.message ?? ""}</p>
            )}
            {errors.availableReservationTimeEnd?.message && <p className="validation-text">{errors.availableReservationTimeEnd?.message ?? ""}</p>}
          </div>
        </form>
      </section>

      <section className="base-abstract-modal__btn-wrap">
        {/* {checkChange && <span className="font14 mr10 text-red">*수정된 사항이 있으니 다시 한번 확인해주세요.</span>} */}
        <BaseButton
          title={"취소"}
          className="color-white"
          onClick={() => {
            setAbstractModalZ1({ isOpen: false });
          }}
        />
        {isEditable && (
          <BaseButton
            title={"저장"}
            onClick={async () => {
              (await trigger()) &&
                setBaseModal({
                  isOpen: true,
                  btnRightTitle: "확인",
                  btnLeftTitle: "취소",
                  title: "저장하시겠습니까?",
                  children: "저장 후에는 변경 사항이 즉시 적용됩니다.",
                  onClick: () => {
                    handleSubmit(onValid)();
                  },
                });
            }}
          />
        )}
      </section>
    </>
  );
};

export default ReservationAvailabilityPeriod;
