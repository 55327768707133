import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { changeBuildingStatusAsync, deleteBuildingAsync, getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { Address, MetaData } from "src/api/public-types";
import { BaseButton, BaseModal } from "src/components";
import AdminMemoIntegration from "src/components/adminMemoIntegration/AdminMemoIntegration";
import { BaseTooltip } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import RegistrationInformation from "src/components/RegistrationInformation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { exitDirectionOptions, heatingFacilityTypeOptions, heatingTypeOptions, Modal, SelectOption } from "src/pages/building/building-types";
import { calculatePyeong, calculateRateOfUse, numberToStringWithComma, ViewYmdFormat } from "src/utils/common-util";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

// 엘리베이터
const getElevatorText = (building: BuildingModel | null): string => {
  let elevatorTexts = [];
  if (Number(building?.passengerElev || 0) > 0) {
    elevatorTexts.push(`승객용 ${building?.passengerElev}`);
  }
  if (building?.freightElev === 0 || Number(building?.freightElev || -1) > -1) {
    elevatorTexts.push(`화물용 ${building?.freightElev}`);
  }
  return elevatorTexts.join(", ");
};

// 냉난방타입
const getHeatingTypeText = (building: BuildingModel | null): string => {
  if (!building?.heatingType || building.heatingType === "HEATING_UNRECOGNIZED") return "";
  let heatingTypeText = "타입: ";
  const heatingTypeOption = heatingTypeOptions.find((option: SelectOption) => option.value === building?.heatingType);
  if (!heatingTypeOption) return heatingTypeText;
  if (heatingTypeOption?.value === "DIRECT_HEATING_AIR_CONDITIONER") {
    // 직접입력 값 (냉난방타입설명)
    heatingTypeText += building?.heatingTypeDesc || "";
  } else {
    // 선택한 냉난방타입
    heatingTypeText += heatingTypeOption.label;
  }
  return heatingTypeText;
};

// 냉난방장비타입
const getHeatingFacilityTypeText = (building: BuildingModel | null): string => {
  if (!building?.heatingFacilityType || building.heatingFacilityType === "HEATINGFACILITY_UNRECOGNIZED") return "";
  let heatingFacilityTypeText = "장비: ";
  const heatingFacilityTypeOption = heatingFacilityTypeOptions.find((option: SelectOption) => option.value === building?.heatingFacilityType);
  if (!heatingFacilityTypeOption) return heatingFacilityTypeText;
  if (heatingFacilityTypeOption?.value === "DIRECT_FACILITY_AIR_CONDITIONER") {
    // 직접입력 값 (냉난방장비타입설명)
    heatingFacilityTypeText += building?.heatingFacilityTypeDesc || "";
  } else {
    // 선택한 냉난방장비타입
    heatingFacilityTypeText += heatingFacilityTypeOption.label;
  }
  return heatingFacilityTypeText;
};

/* 
  건물 > 상세 > 기본 정보
 */
const BasicInfoDetail = () => {
  // 토스트
  // const { openToast } = useToast();

  const navigate = useNavigate();
  const location = useLocation();

  // path variable 에서 buildingId 추출
  const buildingId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathBuildingId = paths[paths.length - 1];
    return Number(pathBuildingId);
  }, [location]);

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  const { isAuthority } = usePartnerAuthority();

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 건물 기본정보 상태 변경 api hook
  const { executeAsync: changeBuildingStatus } = useApiOperation(changeBuildingStatusAsync);

  // 건물 삭제 api hook
  const { executeAsync: deleteBuilding } = useApiOperation(deleteBuildingAsync);

  // title 에 건물명 바인딩
  let buildingName;
  if (building?.buildingName) {
    buildingName = building?.buildingName;
  }

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  const fetchBuilding = useCallback(
    async (buildingId: number) => {
      //
      const { data } = await getBuilding({ buildingId });
      const building: BuildingModel = data?.data?.content?.building;
      setBuilding(building || null);
      //
    },
    [getBuilding],
  );

  useEffect(() => {
    fetchBuilding(buildingId);
  }, [fetchBuilding, buildingId]);

  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    // 모달 닫기
    setConfirmModal({ isOpen: false });

    if (confirmModal.type === "BUILDING_CHANGE_STATUS") {
      // 건물 상태 변경
      const { data: changeBuildingStatusResultData } = await changeBuildingStatus({
        buildingId,
        status: confirmModal.payload,
      });
      if (changeBuildingStatusResultData?.data?.content) {
        // openToast(`정상적으로 변경 되었습니다.`);
        await fetchBuilding(buildingId);
      }
    } else if (confirmModal.type === "BUILDING_DELETE") {
      // 건물 삭제
      const { data: deleteBuildingResultData } = await deleteBuilding({
        buildingId,
      });
      if (deleteBuildingResultData?.data?.content) {
        // openToast(`정상적으로 삭제 되었습니다.`);
        // 목록으로 이동
        navigate(PagePath.building.list);
      }
    }
  };

  // 공개여부 공개 버튼 클릭
  const clickEnabled = useCallback(() => {
    setConfirmModal({
      isOpen: true,
      message: "정말 공개 처리하시겠습니까?",
      type: "BUILDING_CHANGE_STATUS",
      payload: "ENABLED",
    });
  }, []);

  // 공개여부 비공개 버튼 클릭
  const clickDisabled = useCallback(() => {
    setConfirmModal({
      isOpen: true,
      message: "정말 비공개 처리하시겠습니까?",
      type: "BUILDING_CHANGE_STATUS",
      payload: "DISABLED",
    });
  }, []);

  return (
    <>
      <MetaTag subTitle={buildingName} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title={"기본정보"} />
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">건물 명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{building?.buildingName}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">주소</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">
                    {building?.addressList?.map((address: Address) => `(${address.zonecode}) ${address.address} ${address.addressDetail}`)}
                  </span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">Location code</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{building?.locationCode || "-"}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">소개</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14 pre-formatted">{building?.description || "-"}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">지리적 특징</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14 pre-formatted">{building?.feature || "-"}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">규모</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{(building && `지상 ${building?.floorNums}층, 지하 ${building?.undergroundFloorNums}층`) || "-"}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">사용 승인일</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{moment(building?.completionYmd).format(ViewYmdFormat.YYYY_MM_DD) || "-"}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">리모델링 년도</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{building?.remodelingYmd || "-"}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">건축물 용도</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">
                    {building?.buildingStructureTypeList?.map((buildingStructure: MetaData) => buildingStructure.value2).join(", ")}
                  </span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">출입시간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">
                    {building?.entryStartTime} ~ {building?.entryEndTime} {building?.isHoliday && "/ 공휴일 가능"}
                  </span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <div className="flex-center">
                  <p className="mr4">기준 층 임대면적</p>
                  <BaseTooltip touchIcon={"QUESTION"} className="mt1" size={18}>
                    <ul className="pl12">
                      <li className="mb14">임대면적은 건물 전체의 총 면적 중에서 임차인이 사용 가능한 면적을 나타냅니다.</li>
                      <li>로비, 복도, 엘리베이터 등의 공동 이용 영역이나 공용 시설의 일부도 포함됩니다. </li>
                    </ul>
                  </BaseTooltip>
                </div>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  {building && (
                    <span className="font14">
                      {numberToStringWithComma(building?.leasableArea, "0,0.00")} m<sup>2</sup> / {calculatePyeong(building?.leasableArea)} (평)
                    </span>
                  )}
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <div className="flex-center">
                  <p className="mr4">기준 층 전용면적</p>
                  <BaseTooltip touchIcon={"QUESTION"} className="mt1" size={18}>
                    <ul className="pl12">
                      <li className="mb14">전용면적은 일반적으로 아파트나 주택과 같은 개별 주거 공간의 면적을 나타냅니다.</li>
                      <li>벽 안에 포함된 공간으로, 그 밖의 공용 영역(주방, 침실, 거실)은 고려하지 않습니다.</li>
                    </ul>
                  </BaseTooltip>
                </div>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  {building && (
                    <span className="font14">
                      {numberToStringWithComma(building?.leasableAreaNet, "0,0.00")} m<sup>2</sup> / {calculatePyeong(building?.leasableAreaNet)} (평)
                    </span>
                  )}
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <div className="flex-center">
                  <p className="mr4">기준 층 전용률</p>
                  <BaseTooltip touchIcon={"QUESTION"} className="mt1" size={18}>
                    (임대차 계약 상 전유면적÷임대차 계약 상 면적)X100,
                    <br />
                    소수점 2자리 이상 버림=전용률
                  </BaseTooltip>
                </div>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  {building && (
                    <span className="font14">{`${calculateRateOfUse(
                      Number(building?.leasableAreaNet || 0),
                      Number(building?.leasableArea || 0),
                    )} %`}</span>
                  )}
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">주 출입구 방향</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">
                    {exitDirectionOptions.find((option: SelectOption) => option.value === building?.exitDirection)?.label}
                  </span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">화장실</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">
                    {building?.buildingToiletList && building.buildingToiletList.length === 0 && "-"}
                    {building?.buildingToiletList?.map((metaData: MetaData, index: number) => {
                      // 지하 or 지상
                      const floorType = metaData.metaItem === "BUILDING_TOILET_1" || metaData.metaItem === "BUILDING_TOILET_3" ? "지하" : "지상";

                      // 구분 (남여분리 or 남여공용)
                      const type = metaData.metaItem === "BUILDING_TOILET_1" || metaData.metaItem === "BUILDING_TOILET_2" ? "남여분리" : "남여공용";

                      // 갯수 (남1, 여1, 남여1)
                      const count =
                        type === "남여분리"
                          ? `남${Number(metaData?.value2 || 0)}, 여${Number(metaData?.value3 || 0)}`
                          : `남여${Number(metaData?.value2 || 0)}`;

                      return (
                        <div key={index}>
                          {floorType + metaData.value1 + " / " + type + " / " + count} <br />
                        </div>
                      );
                    })}
                  </span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">엘리베이터</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{getElevatorText(building) || "-"}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">냉난방 방식</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  {building && building?.heatingFacilityTypeAddDesc ? (
                    <span className="font14 pre-formatted">
                      {getHeatingTypeText(building)}
                      <br />
                      {getHeatingFacilityTypeText(building)}
                      <br />
                      {building?.heatingFacilityTypeAddDesc}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="text-red900">공개여부</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <div className="minmax50 mr10">
                    <span>{(building?.status === "ENABLED" && "공개중") || (building?.status === "DISABLED" && "비공개")}</span>
                  </div>

                  {isAuthority("w") && (
                    <>
                      {building?.status === "DISABLED" ? (
                        <BaseButton title="공개" className="color-white" onClick={() => clickEnabled()} />
                      ) : (
                        <BaseButton title="비공개" className="color-white" onClick={() => clickDisabled()} />
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>
          </article>

          {/* 등록 정보 */}
          <RegistrationInformation data={building} />

          {/* 관리자메모 */}
          <AdminMemoV3 serviceId={Number(buildingId)} serviceType={ServiceTypes.SERVICE_BUILDING} />
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />

          {isAuthority("d") && (
            <BaseButton
              title="삭제"
              className="no-outline color-white size-large text-red900 ml10"
              onClick={() =>
                setConfirmModal({
                  isOpen: true,
                  message: "정말 삭제 처리하시겠습니까?",
                  type: "BUILDING_DELETE",
                })
              }
            />
          )}
        </div>
        <div className="right-area">
          {isAuthority("w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.building.form}?id=${buildingId}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal isOpen={alertModal.isOpen} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
        <p>{alertModal.message}</p>
      </BaseModal>
    </>
  );
};

export default BasicInfoDetail;
