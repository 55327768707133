import React, { Dispatch, useEffect, useState } from "react";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { BaseButton, BaseCheckbox } from "src/components";
import { useModal } from "src/recoil/modalState/hook";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import produce from "immer";
import moment from "moment";
import { Row } from "react-table";
import { mutationReservationPolicyClosedPeriodAsync, ReservationPolicyClosedPeriod } from "src/api/product/product-api";
import CustomTimePicker from "src/components/CustomTimePicker";
import RangeDatepicker from "src/components/RangeDatepicker";
import { dayOptions, UnionDayOfWeekType } from "src/pages/workOrder/workOrder-types";
import { axiosInstance, YmdFormat } from "src/utils";
import ClosedPeriodTable from "../components/ClosedPeriodTable";
import { DAYS } from "./ClosedPeriod";
import { ReservationProps } from "./ReservationAvailabilityPeriod";
import { createPortal } from "react-dom";
import { useToast } from "src/recoil/toast/hook";

type UnionDay = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT";
export interface ClosedPeriodListForm {
  // id?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  // days?: Array<"SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT">;
  days?: Array<UnionDay>;
  times?: {
    startTime?: string;
    endTime?: string;
  }[];
}

export type ValidationType = {
  feild?: string;
  message?: string;
  isPass?: boolean;
};

const ClosedPeriodRegisterColumns = [
  {
    Header: "휴무 기간",
    accessor: "startDate",
    width: 220,
    Cell: ({
      row,
      serviceId,
      serviceType,
      setClosedPeriodList,
    }: {
      row: Row<ClosedPeriodListForm>;
      serviceId: string;
      serviceType: UnionServiceType;
      setClosedPeriodList: React.Dispatch<React.SetStateAction<ClosedPeriodListForm[]>>;
      validations: ValidationType[];
      setValidations: React.Dispatch<React.SetStateAction<ValidationType[]>>;
    }) => {
      const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

      const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
        const [startDate, endDate] = dateRange;

        setDateRange(dateRange);

        // if (!startDate || !endDate) {
        //   // setValue("startDate", undefined, { shouldDirty: true, shouldValidate: true });
        //   // setValue("endDate", undefined, { shouldDirty: true, shouldValidate: true });
        //   return;
        // }

        // let start = moment(startDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
        // let end = moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";

        setClosedPeriodList(
          produce((draftArr) => {
            draftArr.forEach((draft, index) => {
              if (index === row.index) {
                // console.log("index === row.index :>> ", index === row.index);
                // console.log("draft :>> ", JSON.stringify(draftArr));
                draft.startDate = startDate;
                draft.endDate = endDate;
              }
            });
          }),
        );
      };

      return (
        <div className="minmax220">
          <RangeDatepicker minDate={new Date()} dateRange={dateRange} onChange={handleOnDateRangeChange} />
        </div>
      );
    },
  },
  {
    Header: "휴무 요일",
    accessor: "days",
    width: 320,
    Cell: ({
      row,
      serviceId,
      serviceType,
      setClosedPeriodList,
    }: {
      row: Row<ClosedPeriodListForm>;
      serviceId: string;
      serviceType: UnionServiceType;
      setClosedPeriodList: React.Dispatch<React.SetStateAction<ClosedPeriodListForm[]>>;
      validations: ValidationType[];
    }) => {
      const days = row?.original?.days;
      return (
        <div>
          <div className="flex-center-center h40">
            <BaseCheckbox
              label="전체"
              id={`allCheck-${row.index}`}
              name={`allCheck-${row.index}`}
              className="mr10"
              checked={days?.length === 7}
              onChange={(checked) => {
                if (checked) {
                  // 모든 요일 넣기
                  setClosedPeriodList(
                    produce((draftArr) => {
                      draftArr.forEach((draft, index) => {
                        if (index === row.index) {
                          draft.days = DAYS as any;
                        }
                      });
                    }),
                  );
                } else {
                  // 빈 배열 넣기
                  setClosedPeriodList(
                    produce((draftArr) => {
                      draftArr.forEach((draft, index) => {
                        if (index === row.index) {
                          draft.days = [];
                        }
                      });
                    }),
                  );
                }
              }}
            />
            {dayOptions.map((day, idx: number) => (
              <div key={day.value}>
                <BaseCheckbox
                  id={`${day.value}-${row.index}`}
                  name={`${day.value}-${row.index}`}
                  className="mr4 chip-case-product"
                  label={day.label}
                  checked={row?.original?.days?.some((item: UnionDayOfWeekType) => item === day.value) || false}
                  onChange={(checked: boolean) => {
                    let weeks: UnionDayOfWeekType[] = row?.original?.days ? Array.from(row?.original?.days) : [];
                    if (checked) {
                      weeks.push(day.value);
                    } else {
                      weeks = weeks.filter((week) => week !== day.value);
                    }

                    setClosedPeriodList(
                      produce((draftArr) => {
                        draftArr.forEach((draft, index) => {
                          if (index === row.index) {
                            draft.days = weeks;
                          }
                        });
                      }),
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      );
    },
  },
  {
    Header: "휴무 시간",
    accessor: "times",
    width: 320,
    Cell: ({
      row,
      serviceId,
      serviceType,
      setClosedPeriodList,
    }: {
      row: Row<ClosedPeriodListForm>;
      serviceId: string;
      serviceType: UnionServiceType;
      setClosedPeriodList: React.Dispatch<React.SetStateAction<ClosedPeriodListForm[]>>;
      validations: ValidationType[];
      setValidations: React.Dispatch<React.SetStateAction<ValidationType[]>>;
    }) => {
      const times = row?.original?.times ?? [{ startTime: "", endTime: "" }];

      return times?.map((timeData, timeIndexX) => {
        return (
          <div className="flex-center-center">
            <CustomTimePicker
              name="startTime"
              className="w120 postion-relative"
              timeIntervals={30}
              setDate={(date) => {
                // 휴무시간 > endTime 보다 startTime을 이후 시간으로 했을 때 endTime "" 처리
                const start = moment(date, "HH:mm").format("YYYY-MM-DD HH:mm");
                const end = moment(timeData.endTime, "HH:mm").format("YYYY-MM-DD HH:mm");
                const isStartTimeAfterEnd = moment(start).isAfter(end);

                setClosedPeriodList(
                  produce((draftArr) => {
                    draftArr.forEach((draft, index) => {
                      if (index === row.index) {
                        draft?.times?.forEach((time, timeIndexY) => {
                          if (timeIndexY === timeIndexX) {
                            time.startTime = date;
                            if (isStartTimeAfterEnd) time.endTime = "";
                          }
                        });
                      }
                    });
                  }),
                );
              }}
              selectedTime={timeData.startTime}
              midnight
              placeholder=" "
            />
            <span className="mx10">-</span>
            <CustomTimePicker
              name="endTime"
              className="w120"
              timeIntervals={30}
              setDate={(date) => {
                setClosedPeriodList(
                  produce((draftArr) => {
                    draftArr.forEach((draft, index) => {
                      if (index === row.index) {
                        draft?.times?.forEach((time, timeIndexY) => {
                          if (timeIndexY === timeIndexX) {
                            time.endTime = date;
                          }
                        });
                      }
                    });
                  }),
                );
              }}
              minTime={timeData.startTime}
              selectedTime={timeData.endTime}
              midnight
              placeholder=" "
            />
            <div className="ml10">
              {timeIndexX === 0 ? (
                <div className="flex-center-center">
                  <div
                    className="plus-btn py10"
                    onClick={() => {
                      setClosedPeriodList(
                        produce((draftArr) => {
                          draftArr.forEach((draft, index) => {
                            if (index === row.index) {
                              draft?.times?.push({
                                startTime: "00:00",
                                endTime: "24:00",
                              });
                            }
                          });
                        }),
                      );
                    }}
                  ></div>
                </div>
              ) : (
                <div className="flex-center-center">
                  <button
                    className="minus-btn"
                    onClick={() => {
                      setClosedPeriodList(
                        produce((draftArr) => {
                          draftArr[row.index]?.times?.splice(timeIndexX, 1);
                        }),
                      );
                    }}
                    type="button"
                  />
                </div>
              )}
            </div>
          </div>
        );
      });
    },
  },
  {
    Header: "삭제",
    accessor: "delete",
    width: 50,
    Cell: ({
      row,
      serviceId,
      serviceType,
      setClosedPeriodList,
    }: {
      row: Row<ClosedPeriodListForm>;
      serviceId: string;
      serviceType: UnionServiceType;
      setClosedPeriodList: React.Dispatch<React.SetStateAction<ClosedPeriodListForm[]>>;
    }) => {
      return (
        <button
          className="base-trash-btn color-bg-gray h40"
          onClick={() => {
            setClosedPeriodList(
              produce((draftArr) => {
                draftArr.splice(row.index, 1);
              }),
            );
          }}
        ></button>
      );
    },
  },
];

const ClosedPeriodRegister = ({ spaceType, space, serviceType, serviceId, isDefault, setIsChangeValue }: ReservationProps) => {
  const [validations, setValidations] = useState<ValidationType[]>([]);

  const { setBaseModal, setAbstractModalZ2, setAbstractModalZ1 } = useModal();
  const queryClient = useQueryClient();

  const defaultValue = {
    startDate: null,
    endDate: null,
    days: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"] as Array<UnionDay>,
    times: [
      {
        startTime: "00:00",
        endTime: "24:00",
      },
    ],
  };

  const [closedPeriodList, setClosedPeriodList] = useState<ClosedPeriodListForm[]>([defaultValue]);

  const { mutate } = useMutation({
    mutationFn: (data: ReservationPolicyClosedPeriod[]) => mutationReservationPolicyClosedPeriodAsync(axiosInstance, data),
  });
  const { openToast } = useToast();

  // 필수값 데이터 적용시 validation 통과 적용
  useEffect(() => {
    if (validations.length > 0) {
      const dateRequired = closedPeriodList.some((item) => !item.startDate || !item.endDate);
      const daysRequired = closedPeriodList.some((item) => item.days?.length === 0);
      const timeRequired = closedPeriodList.some((item) => item.times?.some((time) => !time.startTime || !time.endTime));

      !dateRequired && setValidations((prev) => prev.filter((item) => item.feild !== "date"));
      !daysRequired && setValidations((prev) => prev.filter((item) => item.feild !== "days"));
      !timeRequired && setValidations((prev) => prev.filter((item) => item.feild !== "time"));
    }
  }, [closedPeriodList]);

  // 등록 버튼 클릭시 > 유효성 검사 확인
  const handleValidation = (): boolean => {
    let pass = true;

    closedPeriodList.forEach((item) => {
      if (!item.startDate || !item.endDate) {
        pass = false;
        setValidations((prev) => [...prev, { message: "휴무 기간을 선택해주세요.", feild: "date" }]);
      }

      if (item.days && item.days.length === 0) {
        pass = false;
        setValidations((prev) => [...prev, { message: "휴무 요일을 선택해주세요.", feild: "days" }]);
      }

      if (item.times?.some((time) => !time.startTime || !time.endTime)) {
        pass = false;
        setValidations((prev) => [...prev, { message: "휴무 시간을 선택해주세요.", feild: "time" }]);
      }
    });

    if (pass) onCreateClosedData();
    return false;
  };

  const onCreateClosedData = () => {
    setBaseModal({
      isOpen: true,
      title: "저장하시겠습니까?",
      children: "저장 후에는 변경 사항이 즉시 적용됩니다.",
      btnLeftTitle: "취소",
      btnRightTitle: "확인",
      onClick: () => {
        const reservationPolicyClosedPeriod: ReservationPolicyClosedPeriod[] = [];
        closedPeriodList.forEach((closedPeriod) => {
          closedPeriod.times?.forEach((time) => {
            reservationPolicyClosedPeriod.push({
              serviceId: Number(serviceId), // 서비스ID
              serviceType: serviceType, // 서비스타입
              commonFacilityType: spaceType, // 공용공간타입
              isDefault: isDefault, // 기본설정여부
              cmdType: "C",
              ...(!isDefault && { buildingCommonFacilityId: Number(space?.id) }), // 건물-공용공간 ID (isDefault=false인 경우 필수)})

              days: closedPeriod?.days?.join(","),
              startDate: moment(closedPeriod.startDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00",
              endDate: moment(closedPeriod.endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
              startTime: time.startTime,
              endTime: time.endTime,
            });
          });
        });

        mutate(reservationPolicyClosedPeriod, {
          onSuccess: (data) => {
            console.log("onSuccess data :>> ", data);
            queryClient.invalidateQueries({
              queryKey: ["getReservationPolicyClosedPeriodAsync"],
            });
            setBaseModal({ isOpen: false });
            setAbstractModalZ2({ isOpen: false });
          },
        });
      },
    });
  };

  return (
    <>
      <section className="base-abstract-modal__title">
        <h1>휴무일 등록</h1>
      </section>
      <section className="base-abstract-modal__contents-minmax-height px30">
        <ClosedPeriodTable
          setClosedPeriodList={setClosedPeriodList}
          columns={ClosedPeriodRegisterColumns}
          data={closedPeriodList ?? []}
          validations={validations}
          setValidations={setValidations}
        ></ClosedPeriodTable>
        <section className="d-flex flex-center-center pt20" style={{ position: "sticky", bottom: 0, zIndex: 99, background: "#ffffff" }}>
          <BaseButton
            className="color-white w278"
            title="새 휴무일 추가"
            onClick={() => {
              setClosedPeriodList((prev) => [...prev, defaultValue]);
            }}
          ></BaseButton>
        </section>
      </section>

      <section className="base-abstract-modal__btn-wrap">
        {closedPeriodList.length > 0 && (
          <div className="flex-center">
            {/* 휴무 기간 메세지 */}
            {validations?.some((item) => item.feild === "date") && (
              <p className="validation-text">{validations?.find((item) => item.feild === "date")?.message}</p>
            )}
            {/* 휴무 요일 메세지 */}
            {!validations?.some((item) => item.feild === "date") && validations?.some((item) => item.feild === "days") && (
              <p className="validation-text">{validations?.find((item) => item.feild === "days")?.message}</p>
            )}
            {/* 휴무 시간 메세지 */}
            {!validations?.some((item) => item.feild === "date") &&
              !validations?.some((item) => item.feild === "days") &&
              validations?.some((item) => item.feild === "time") && (
                <p className="validation-text">{validations?.find((item) => item.feild === "time")?.message}</p>
              )}
          </div>
        )}

        <BaseButton title={"취소"} className="color-white" onClick={() => setAbstractModalZ2({ isOpen: false })} />
        <BaseButton
          title={"등록"}
          className=""
          disabled={closedPeriodList.length === 0}
          onClick={() => {
            handleValidation();
          }}
        />
      </section>
    </>
  );
};

export default ClosedPeriodRegister;
