import React, { useEffect, useState } from "react";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { BaseButton, BaseCheckbox } from "src/components";
import { SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import produce from "immer";
import moment from "moment";
import { Row } from "react-table";
import { mutationReservationPolicyClosedPeriodAsync, ReservationPolicyClosedPeriod } from "src/api/product/product-api";
import CustomTimePicker from "src/components/CustomTimePicker";
import RangeDatepicker from "src/components/RangeDatepicker";
import { dayOptions, UnionDayOfWeekType } from "src/pages/workOrder/workOrder-types";
import { axiosInstance, YmdFormat } from "src/utils";
import ClosedPeriodTable from "../components/ClosedPeriodTable";
import { DAYS } from "./ClosedPeriod";
import { ValidationType } from "./ClosedPeriodRegister";

export interface ClosedPeriodListForm {
  // id?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  days?: Array<"SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT">;
  times?: {
    startTime?: string;
    endTime?: string;
  }[];
}

const ClosedPeriodEditColumns = [
  {
    Header: "휴무 기간",
    accessor: "startDate",
    width: 260,
    Cell: ({
      row,
      serviceId,
      serviceType,
      setClosedPeriodList,
    }: {
      row: Row<ClosedPeriodListForm>;
      serviceId: string;
      serviceType: UnionServiceType;
      setClosedPeriodList: React.Dispatch<React.SetStateAction<ClosedPeriodListForm[]>>;
    }) => {
      const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([row.original.startDate!, row.original.endDate!]);

      const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
        const [startDate, endDate] = dateRange;
        setDateRange(dateRange);
        // if (!startDate || !endDate) {
        //   // setValue("startDate", undefined, { shouldDirty: true, shouldValidate: true });
        //   // setValue("endDate", undefined, { shouldDirty: true, shouldValidate: true });
        //   return;
        // }

        // let start = moment(startDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
        // let end = moment(endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";

        setClosedPeriodList(
          produce((draftArr) => {
            draftArr.forEach((draft, index) => {
              if (index === row.index) {
                // console.log("index === row.index :>> ", index === row.index);
                // console.log("draft :>> ", JSON.stringify(draftArr));
                draft.startDate = startDate;
                draft.endDate = endDate;
              }
            });
          }),
        );
      };
      return (
        <div className="minmax220">
          <RangeDatepicker minDate={new Date()} dateRange={dateRange} onChange={handleOnDateRangeChange} />
        </div>
      );
    },
  },
  {
    Header: "휴무 요일",
    accessor: "days",
    width: 354,
    Cell: ({
      row,
      serviceId,
      serviceType,
      setClosedPeriodList,
    }: {
      row: Row<ClosedPeriodListForm>;
      serviceId: string;
      serviceType: UnionServiceType;
      setClosedPeriodList: React.Dispatch<React.SetStateAction<ClosedPeriodListForm[]>>;
    }) => {
      const days = row?.original?.days;
      return (
        <div className="flex-center-center h40">
          <BaseCheckbox
            label="전체"
            id={`allCheck-${row.index}`}
            name={`allCheck-${row.index}`}
            className="mr10"
            checked={days?.length === 7}
            onChange={(checked) => {
              if (checked) {
                // 모든 요일 넣기
                setClosedPeriodList(
                  produce((draftArr) => {
                    draftArr.forEach((draft, index) => {
                      if (index === row.index) {
                        draft.days = DAYS as any;
                      }
                    });
                  }),
                );
              } else {
                // 빈 배열 넣기
                setClosedPeriodList(
                  produce((draftArr) => {
                    draftArr.forEach((draft, index) => {
                      if (index === row.index) {
                        draft.days = [];
                      }
                    });
                  }),
                );
              }
            }}
          />
          {dayOptions.map((day, idx: number) => (
            <div key={day.value}>
              <BaseCheckbox
                id={`${day.value}-${row.index}`}
                name={`${day.value}-${row.index}`}
                className="mr4 chip-case-product"
                label={day.label}
                checked={row?.original?.days?.some((item: UnionDayOfWeekType) => item === day.value) || false}
                onChange={(checked: boolean) => {
                  let weeks: UnionDayOfWeekType[] = row?.original?.days ? Array.from(row?.original?.days) : [];
                  if (checked) {
                    weeks.push(day.value);
                  } else {
                    weeks = weeks.filter((week) => week !== day.value);
                  }

                  console.log("weeks :>> ", weeks);

                  setClosedPeriodList(
                    produce((draftArr) => {
                      draftArr.forEach((draft, index) => {
                        if (index === row.index) {
                          draft.days = weeks;
                        }
                      });
                    }),
                  );
                }}
              />
            </div>
          ))}
        </div>
      );
    },
  },
  {
    Header: "휴무 시간",
    accessor: "times",
    width: 320,
    Cell: ({
      row,
      serviceId,
      serviceType,
      setClosedPeriodList,
    }: {
      row: Row<ClosedPeriodListForm>;
      serviceId: string;
      serviceType: UnionServiceType;
      setClosedPeriodList: React.Dispatch<React.SetStateAction<ClosedPeriodListForm[]>>;
    }) => {
      const times = row?.original?.times ?? [{ startTime: "", endTime: "" }];
      return times?.map((timeData, timeIndexX) => {
        return (
          <div className="flex-center-center">
            <CustomTimePicker
              name="startTime"
              className="w120"
              timeIntervals={30}
              setDate={(date) => {
                console.log("date :>> ", date);
                // 휴무시간 > endTime 보다 startTime을 이후 시간으로 했을 때 endTime "" 처리
                const start = moment(date, "HH:mm").format("YYYY-MM-DD HH:mm");
                const end = moment(timeData.endTime, "HH:mm").format("YYYY-MM-DD HH:mm");
                const isStartTimeAfterEnd = moment(start).isAfter(end);

                setClosedPeriodList(
                  produce((draftArr) => {
                    draftArr.forEach((draft, index) => {
                      if (index === row.index) {
                        draft?.times?.forEach((time, timeIndexY) => {
                          if (timeIndexY === timeIndexX) {
                            time.startTime = date;
                            if (isStartTimeAfterEnd) time.endTime = "";
                          }
                        });
                      }
                    });
                  }),
                );
              }}
              selectedTime={timeData.startTime}
              midnight
              placeholder=" "
            />
            <span className="mx10">-</span>
            <CustomTimePicker
              name="endTime"
              className="w120"
              timeIntervals={30}
              minTime={timeData.startTime}
              setDate={(date) => {
                setClosedPeriodList(
                  produce((draftArr) => {
                    draftArr.forEach((draft, index) => {
                      if (index === row.index) {
                        draft?.times?.forEach((time, timeIndexY) => {
                          if (timeIndexY === timeIndexX) {
                            time.endTime = date;
                          }
                        });
                      }
                    });
                  }),
                );
              }}
              selectedTime={timeData.endTime}
              midnight
              placeholder=" "
            />
          </div>
        );
      });
    },
  },
];

interface Props {
  spaceType: SpaceTypeT;
  serviceType: UnionServiceType;
  serviceId: string;
  isEditable?: boolean;
  setIsChangeValue?: React.Dispatch<React.SetStateAction<boolean>>;
  reservationPolicyClosedPeriodProps: ReservationPolicyClosedPeriod;
  space?: any;
  isDefault: boolean;
}

const ClosedPeriodEdit = ({
  spaceType,
  serviceType,
  serviceId,
  isEditable,
  setIsChangeValue,
  reservationPolicyClosedPeriodProps,
  space,
  isDefault,
}: Props) => {
  const { setBaseModal, setAbstractModalZ2, setAbstractModalZ1 } = useModal();
  const queryClient = useQueryClient();
  const [validations, setValidations] = useState<ValidationType[]>([]);

  const defaultValue = {
    startDate: moment(reservationPolicyClosedPeriodProps?.startDate).toDate(),
    endDate: moment(reservationPolicyClosedPeriodProps?.endDate).toDate(),
    days: reservationPolicyClosedPeriodProps.days?.split(",") as any,
    times: [
      {
        startTime: reservationPolicyClosedPeriodProps.startTime,
        endTime: reservationPolicyClosedPeriodProps.endTime,
      },
    ],
  };

  const [closedPeriodList, setClosedPeriodList] = useState<ClosedPeriodListForm[]>([defaultValue]);

  const { mutate } = useMutation({
    mutationFn: (data: ReservationPolicyClosedPeriod[]) => mutationReservationPolicyClosedPeriodAsync(axiosInstance, data),
  });
  useEffect(() => {
    if (validations.length > 0) {
      const dateRequired = closedPeriodList.some((item) => !item.startDate || !item.endDate);
      const daysRequired = closedPeriodList.some((item) => item.days?.length === 0);

      const timeRequired = closedPeriodList.some((item) => item.times?.some((time) => !time.startTime || !time.endTime));

      !dateRequired && setValidations((prev) => prev.filter((item) => item.feild !== "date"));
      !daysRequired && setValidations((prev) => prev.filter((item) => item.feild !== "days"));
      !timeRequired && setValidations((prev) => prev.filter((item) => item.feild !== "time"));
    }
  }, [closedPeriodList]);

  const handleValidation = (): boolean => {
    let pass = true;

    closedPeriodList.forEach((item) => {
      if (!item.startDate || !item.endDate) {
        pass = false;
        setValidations((prev) => [...prev, { message: "휴무 기간을 선택해주세요.", feild: "date" }]);
      }

      if (item.days && item.days.length === 0) {
        pass = false;
        setValidations((prev) => [...prev, { message: "휴무 요일을 선택해주세요.", feild: "days" }]);
      }

      if (item.times?.some((time) => !time.startTime || !time.endTime)) {
        pass = false;
        setValidations((prev) => [...prev, { message: "휴무 시간을 선택해주세요.", feild: "time" }]);
      }
    });

    if (pass) onEditClosedData();
    return false;
  };

  const onEditClosedData = () => {
    setBaseModal({
      isOpen: true,
      title: "저장하시겠습니까?",
      children: "저장 후에는 변경 사항이 즉시 적용됩니다.",
      btnLeftTitle: "취소",
      btnRightTitle: "확인",
      onClick: () => {
        const reservationPolicyClosedPeriod: ReservationPolicyClosedPeriod[] = [];

        console.log(
          'moment(closedPeriod.startDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00" :>> ',
          moment(closedPeriodList[0].startDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00",
        );
        console.log("closedPeriodList :>> ", closedPeriodList);

        closedPeriodList.forEach((closedPeriod) => {
          closedPeriod.times?.forEach((time) => {
            reservationPolicyClosedPeriod.push({
              //수정 페이지라 id 추가
              id: reservationPolicyClosedPeriodProps.id,
              //개별설정일때 건물 공용공간id 추가
              buildingCommonFacilityId: Number(space?.id),
              serviceId: Number(serviceId), // 서비스ID
              serviceType: serviceType, // 서비스타입
              commonFacilityType: spaceType, // 공용공간타입
              isDefault: isDefault, // 기본설정여부
              cmdType: "U",

              days: closedPeriod?.days?.filter((val) => !!val)?.join(","),
              startDate: moment(closedPeriod.startDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00",
              endDate: moment(closedPeriod.endDate).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00",
              startTime: time.startTime,
              endTime: time.endTime,
            });
          });
        });

        console.log("reservationPolicyClosedPeriod :>> ", reservationPolicyClosedPeriod);

        mutate(reservationPolicyClosedPeriod, {
          onSuccess: (data) => {
            console.log("onSuccess data :>> ", data);
            queryClient.refetchQueries({
              queryKey: ["getReservationPolicyClosedPeriodAsync"],
            });
            setBaseModal({ isOpen: false });
            setAbstractModalZ2({ isOpen: false });
          },
        });
      },
    });
  };

  return (
    <>
      <section className="base-abstract-modal__contents px30">
        <div className="base-abstract-modal__contents-subtitle">
          <span className="font-weight-bold" style={{ fontSize: 24 }}>
            휴무일 수정
          </span>
        </div>

        <section className="base-abstract-modal__contents-minmax-height">
          <ClosedPeriodTable
            setClosedPeriodList={setClosedPeriodList}
            columns={ClosedPeriodEditColumns}
            data={closedPeriodList ?? []}
          ></ClosedPeriodTable>
        </section>
        {/* <div className="d-flex flex-center-center">
          <BaseButton
            className="color-white w278"
            title="새 휴무일 추가"
            onClick={() => {
              setClosedPeriodList((prev) => [...prev, defaultValue]);
            }}
          ></BaseButton>
        </div> */}
      </section>

      <section className="base-abstract-modal__btn-wrap">
        {closedPeriodList.length > 0 && (
          <div className="flex-center">
            {/* 휴무 기간 메세지 */}
            {validations?.some((item) => item.feild === "date") && (
              <p className="validation-text">{validations?.find((item) => item.feild === "date")?.message}</p>
            )}
            {/* 휴무 요일 메세지 */}
            {!validations?.some((item) => item.feild === "date") && validations?.some((item) => item.feild === "days") && (
              <p className="validation-text">{validations?.find((item) => item.feild === "days")?.message}</p>
            )}
            {/* 휴무 시간 메세지 */}
            {!validations?.some((item) => item.feild === "date") &&
              !validations?.some((item) => item.feild === "days") &&
              validations?.some((item) => item.feild === "time") && (
                <p className="validation-text">{validations?.find((item) => item.feild === "time")?.message}</p>
              )}
          </div>
        )}

        {/* {checkChange && <span className="font14 mr10 text-red">*수정된 사항이 있으니 다시 한번 확인해주세요.</span>} */}
        <BaseButton title={"취소"} className="color-white" onClick={() => setAbstractModalZ2({ isOpen: false })} />
        <BaseButton
          title={"저장"}
          className=""
          onClick={() => {
            handleValidation();
          }}
        />
      </section>
    </>
  );
};

export default ClosedPeriodEdit;
