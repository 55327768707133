import { useEffect, useMemo, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { CommonFacilityModel } from "src/api/building/building-types";
import { ReservationPolicyMode } from "src/api/product/product-api";
import { useQueryParams } from "src/hooks/useQueryParams";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import AllSpaceUsageLimitModal from "./AllSpaceUsageLimitModal";
import ClosedPeriod from "./ClosedPeriod";
import Price from "./Price";
import ReservationAvailabilityPeriod from "./ReservationAvailabilityPeriod";
import ReservationTimeUnit from "./ReservationTimeUnit";
import SpaceUsageLimitModal from "./SpaceUsageLimitModal";
import { DetailTotalData } from "src/api/contract/contract-types";
import ContractAllSpaceUsageLimitModal from "./ContractAllSpaceUsageLimitModal";
import ContractSpaceUsageLimitModal from "./ContractSpaceUsageLimitModal";
import useGetProduct from "src/hooks/product/useGetProduct";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

export interface DefaultSettingModalProps {
  spaceType: SpaceTypeT;
  serviceType: UnionServiceType;
  serviceId: string; // 신청 계약에서 계약아이디와 계약 관리아이디 구분해서 써야함
  isDefault: boolean; // 기본설정 뷰에서 true 고정 , 개별설정 뷰에서 false 고정
  reservationPolicyModeList?: ReservationPolicyMode[]; // // 개별설정에서는 필수,  보낼때 공용공간 타입 구분해서 보내주세요
  buildingCommonFacilityId?: string; // 개별설정에서는 필수
  space?: CommonFacilityModel; // 개별설정에서는 필수 공용공간 정보 보내주세요
  detailData?: DetailTotalData; //신청계약 전용
  // [key: string]: any;
}

const DefaultSettingModal = ({
  spaceType,
  serviceType,
  serviceId,
  space,
  isDefault,
  reservationPolicyModeList,
  detailData,
}: DefaultSettingModalProps) => {
  const { queryParams } = useQueryParams<{ id?: string }>();
  const params = useParams();

  const { isAuthority } = usePartnerAuthority();

  const productId = queryParams.id ? queryParams.id : params.id;
  const { data: product } = useGetProduct({ productId: isDefault === false && serviceType === "SERVICE_PRODUCT" ? productId : undefined });

  const [isChangeValue, setIsChangeValue] = useState(false);
  const { setBaseModal } = useModal();
  const [tabs, setTabs] = useState<{ value: string; label: string; isActive: boolean }[]>([
    { value: "ReservationAvailabilityPeriod", label: "예약 가능 기간", isActive: true },
    { value: "ReservationTimeUnit", label: "예약 시간 단위", isActive: false },
    { value: "ClosedPeriod", label: "휴무일", isActive: false },
    { value: "usage", label: "이용한도", isActive: false },
    { value: "price", label: "요금", isActive: false },
  ]);

  useEffect(() => {
    if (isDefault === true) {
      setTabs((prev) => prev.filter((tab) => tab.value !== "price"));
    }
  }, [isDefault]);

  const onChangeTab = (tabValue: string) => {
    console.log("tabValue, isChangeValue", tabValue, isChangeValue);
    setTabs(tabs.map((tab) => ({ ...tab, isActive: tab.value === tabValue ? true : false })));
    // if (isChangeValue) {
    //   return setBaseModal({
    //     isOpen: true,
    //     title: "변경사항이 있습니다 저장하지 않고 탭을 변경하시곘습니까?",
    //     btnLeftTitle: "취소",
    //     btnRightTitle: "확인",
    //     onClick: () => {
    //       setIsChangeValue(false);
    //       setTabs(tabs.map((tab) => ({ ...tab, isActive: tab.value === tabValue ? true : false })));
    //       setBaseModal({ isOpen: false });
    //     },
    //     onClose: () => {
    //       setBaseModal({ isOpen: false });
    //     },
    //   });
    // } else {
    //   setTabs(tabs.map((tab) => ({ ...tab, isActive: tab.value === tabValue ? true : false })));
    // }
  };

  <span>{detailData?.contract?.contractName ?? detailData?.contractManage?.spaceProductName ?? "-"}</span>;

  return (
    <>
      <div className="border-bottom-gray">
        <section className="base-abstract-modal__title pb20">
          <div className="w-100 flex-center-start gap10">
            <h1>
              {SpaceTypeLabel[spaceType]} 예약 {isDefault ? "기본" : " 개별"} 정책 설정
            </h1>
            {isDefault === false && (
              //개별설정일때
              //상품: #{상품명} > #{공용공간 유형} > #{공용공간 명}
              //계약: #{계약명(없으면 상품명)} > #{공용공간 유형} > #{공용공간 명}
              <div
                className="flex-center-center gap5"
                style={{ fontWeight: 600, fontSize: 14, background: "#F1F1F1", padding: "5px 12px", borderRadius: 50 }}
              >
                {serviceType === "SERVICE_PRODUCT" ? (
                  <span>{product?.productName ?? "-"}</span>
                ) : (
                  <span>{detailData?.contractManage?.contractName || detailData?.contractManage?.spaceProductName || "-"}</span>
                )}
                {">"}
                <span>{SpaceTypeLabel[spaceType] ?? "-"}</span>
                {">"}
                {spaceType === "DESK" ? (
                  <>
                    <span>{space?.groupName ?? "-"}</span>
                    {">"}
                    <span>{space?.facilityName ?? "-"}</span>
                  </>
                ) : (
                  <span>{space?.facilityName ?? "-"}</span>
                )}
              </div>
            )}
          </div>
        </section>
        <div className="contents-title__tab px30">
          {tabs?.map((tab) => {
            return (
              <div key={tab.value} className={`${tab?.isActive ? "active" : ""}`} onClick={() => onChangeTab(tab.value)}>
                <span>{tab.label}</span>
              </div>
            );
          })}
        </div>
      </div>

      {tabs.map((tab) => {
        if (tab.value === "price" && tab.isActive) {
          if (serviceType === "SERVICE_PRODUCT") {
            return (
              <Price
                key={tab.value}
                space={space as any}
                isEditable={isAuthority("w")}
                productId={productId}
                serviceId={serviceId}
                serviceType={serviceType}
                isChangeValue={isChangeValue}
                setIsChangeValue={setIsChangeValue}
              />
            );
          } else {
            //신청계약
            return (
              <Price
                key={tab.value}
                space={space as any}
                isEditable={isAuthority("w")}
                productId={detailData?.product?.id}
                serviceId={String(detailData?.contract?.contractId)}
                serviceType="SERVICE_CONTRACT"
                isChangeValue={isChangeValue}
                setIsChangeValue={setIsChangeValue}
              />
            );
          }
        }

        if (tab.value === "ReservationAvailabilityPeriod" && tab.isActive) {
          return (
            <ReservationAvailabilityPeriod
              key={tab.value}
              spaceType={spaceType}
              serviceId={serviceId}
              serviceType={serviceType}
              isEditable={isAuthority("w")}
              isDefault={isDefault}
              isChangeValue={isChangeValue}
              setIsChangeValue={setIsChangeValue}
              reservationPolicyModeList={reservationPolicyModeList}
              space={space}
            />
          );
        }

        if (tab.value === "ReservationTimeUnit" && tab.isActive) {
          return (
            <ReservationTimeUnit
              key={tab.value}
              spaceType={spaceType}
              serviceId={serviceId}
              serviceType={serviceType}
              isEditable={isAuthority("w")}
              isDefault={isDefault}
              isChangeValue={isChangeValue}
              setIsChangeValue={setIsChangeValue}
              reservationPolicyModeList={reservationPolicyModeList}
              space={space}
            />
          );
        }
        if (tab.value === "ClosedPeriod" && tab.isActive) {
          return (
            <ClosedPeriod
              key={tab.value}
              spaceType={spaceType}
              serviceId={serviceId}
              serviceType={serviceType}
              isEditable={isAuthority("w")}
              isRemovable={isAuthority("d")}
              isDefault={isDefault}
              isChangeValue={isChangeValue}
              setIsChangeValue={setIsChangeValue}
              reservationPolicyModeList={reservationPolicyModeList}
              space={space}
            />
          );
        }
        if (tab.value === "usage" && tab.isActive) {
          if (isDefault === true) {
            //기본설정

            if (serviceType === "SERVICE_PRODUCT") {
              return (
                <AllSpaceUsageLimitModal
                  key={tab.value}
                  spaceType={spaceType}
                  serviceId={serviceId}
                  serviceType={serviceType}
                  isEditable={isAuthority("w")}
                  isChangeValue={isChangeValue}
                  setIsChangeValue={setIsChangeValue}
                />
              );
            } else {
              //신청계약이라면
              if (!detailData) {
                return null;
              }
              return (
                <ContractAllSpaceUsageLimitModal
                  key={tab.value}
                  detailData={detailData}
                  spaceType={spaceType}
                  isEditable={isAuthority("w")}
                  isChangeValue={isChangeValue}
                  setIsChangeValue={setIsChangeValue}
                ></ContractAllSpaceUsageLimitModal>
              );
            }
          } else {
            //개별설정
            if (serviceType === "SERVICE_PRODUCT") {
              return (
                <SpaceUsageLimitModal
                  key={tab.value}
                  spaceType={spaceType}
                  space={space!}
                  isChangeValue={isChangeValue}
                  setIsChangeValue={setIsChangeValue}
                  isEditable={isAuthority("w")}
                ></SpaceUsageLimitModal>
              );
            } else {
              if (!detailData || !space) {
                return null;
              }
              //신청계약일때
              return (
                <ContractSpaceUsageLimitModal
                  key={tab.value}
                  space={space}
                  detailData={detailData}
                  isChangeValue={isChangeValue}
                  setIsChangeValue={setIsChangeValue}
                  isEditable={isAuthority("w")}
                ></ContractSpaceUsageLimitModal>
              );
            }
          }
        }
      })}
    </>
  );
};

export default DefaultSettingModal;
