import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BillingDetail } from "src/api/billingSchedule/billing-type";
import { editScheduleRoundList } from "src/api/billingSchedule/billingSchedule-api";
import {
  ContractDetail,
  ContractManagaDetail,
  disabledBeforeContractAccept,
  disabledBillingStartEnd,
  disabledScheduleBillEdit,
  monthDays,
} from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseDatePicker, BaseInput, BaseRadio, BaseSelect, BaseTooltip } from "src/components";
import BaseSection from "src/components/layout/BaseSection";
import PaymentStatusView from "src/pages/billing/components/PaymentStatusView";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat } from "src/utils";
import useContactApi from "../../hooks/useContractApi";
import OverdueFeePopup from "../modal/OverdueFeePopup";

interface BasicProps {
  detailData: any;
  saveFlags: boolean;
  setEditable: any;
  setSaveFlags: any;
  textGrey: string;
  contractManage: ContractManagaDetail;
  contract: ContractDetail;
  rentalSchedule: BillingDetail;
  gotoBillingScheduleDetailList: Function;
  provider: any;
  setData: Function;
  popupState: boolean;
  setPopupState: any;
  setCommonModals: any;
  setEditConfrim: any;
}
const BillingScheduleEdit = ({
  detailData,
  saveFlags,
  setEditable,
  setSaveFlags,
  textGrey,
  contractManage,
  contract,
  rentalSchedule,
  gotoBillingScheduleDetailList,
  provider,
  setData,
  setCommonModals,
  setPopupState,
  setEditConfrim,
}: BasicProps) => {
  const { saveContractBillData, getSchedulesRounds } = useContactApi();
  const { executeAsync: updateRoundList } = useApiOperation(editScheduleRoundList);
  const { openToast } = useToast();
  const [editRentalSchedule, setEditRentalSchedule] = useState<BillingDetail>();
  const [isOverdueFeeOpen, setIsOverdueFeeOpen] = useState(false);
  const [s2Rounds, setS2Rounds] = useState<any>(null);
  const [s2Ori, setS2Ori] = useState<any>(null);
  const findRounds = useCallback(
    async (scheduleId: string) => {
      const getRounds: any = await getSchedulesRounds(scheduleId);
      if (Array.isArray(getRounds) && getRounds.length > 0) {
        console.log("getRounds[0]", getRounds[0]);
        setS2Rounds(getRounds[0]);
        setS2Ori(getRounds[0]);
      }
    },
    [getSchedulesRounds],
  );
  const updateRounds = useCallback(
    async (s2Rounds: any) => {
      const response: any = await updateRoundList({ scheduleId: Number(rentalSchedule.scheduleId), billList: [s2Rounds] });

      return { response };
    },
    [rentalSchedule.scheduleId, updateRoundList],
  );

  const onSaveBillingsData = useCallback(async () => {
    let result: any = {};

    if (detailData.product.productType !== "TIME_COURT") {
      result = await saveContractBillData(rentalSchedule, editRentalSchedule!);
    } else {
      const send = {
        billId: s2Rounds.bill.billId,
        taxInvoiceIssueDate: s2Rounds.bill.taxInvoiceIssueDate,
        billDueDate: s2Rounds?.bill?.billDueDate,
      };
      result = await updateRounds(send);
    }
    console.log("result", result);
    if (result.response.status > 199 && result.response.status < 300) {
      let ms: any = {
        message: "저장 되었습니다.",
      };
      if (detailData.product.productType !== "TIME_COURT") {
        ms.title = "이용자 결제 정보 변경";
      } else {
        ms.title = "결제 정보 변경";
      }
      setCommonModals(ms);
      setPopupState(true);
      setEditConfrim(false);
      setSaveFlags(false);
      setEditable(false);
      setData();
      return;
    }
    if (result.validationPass === false) {
      setCommonModals({
        message: result.message,
        title: "이용자 결제 정보 변경",
      });
      setPopupState(true);
      setSaveFlags(false);
      // setEditable(false);
      setEditConfrim(false);
      return;
    }
  }, [
    detailData.product.productType,
    saveContractBillData,
    rentalSchedule,
    editRentalSchedule,
    s2Rounds?.bill?.billId,
    s2Rounds?.bill?.taxInvoiceIssueDate,
    s2Rounds?.bill?.billDueDate,
    updateRounds,
    setCommonModals,
    setPopupState,
    setEditConfrim,
    setSaveFlags,
    setEditable,
    setData,
  ]);

  useEffect(() => {
    if (saveFlags === true) {
      onSaveBillingsData();
    }
  }, [onSaveBillingsData, saveFlags]);

  const initDatas = useCallback(() => {
    setEditRentalSchedule(rentalSchedule);
  }, [rentalSchedule]);

  useEffect(() => {
    initDatas();
    if (rentalSchedule && detailData?.product?.productType === "TIME_COURT") {
      findRounds(String(rentalSchedule?.scheduleId!));
    }
  }, [initDatas, rentalSchedule, findRounds, detailData?.product?.productType]);
  return detailData?.product?.productType !== "TIME_COURT" ? (
    <div>
      <div className="flex-center">
        <section className={textGrey}>
          <div className="contents-container__grid-index">
            <p>빌링 기간</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <div className="minmax100 mr5">
                <BaseDatePicker
                  // className={_.isEqual(rentalSchedule?.scheduleStartTime, editRentalSchedule?.scheduleStartTime) ? "" : ""}
                  readonly={disabledBillingStartEnd.includes(String(contract?.contractStep))}
                  name={"scheduleStartTime"}
                  type="date"
                  selectedDate={editRentalSchedule?.scheduleStartTime ? moment(editRentalSchedule?.scheduleStartTime).toDate() : null}
                  setDate={(value: string) => {
                    let day = Number(moment(value).format("DD"));
                    if (27 < Number(day)) {
                      day = 28;
                    }
                    const temp = _.cloneDeep(editRentalSchedule!);

                    temp.scheduleStartTime = value;
                    temp.payRegularDay = day;
                    temp.taxInvoiceIssueDay = day;

                    setEditRentalSchedule(temp);
                  }}
                  minDate={moment(contract?.contractStartTime).toDate()}
                  maxDate={moment(contract?.contractEndTime).toDate()}
                />
              </div>
              <div className=""> ~ </div>
              <div className="minmax100 ml5">
                <BaseDatePicker
                  readonly={disabledBillingStartEnd.includes(String(contract?.contractStep))}
                  name={"scheduleEndTime"}
                  type="date"
                  selectedDate={editRentalSchedule?.scheduleEndTime ? moment(editRentalSchedule?.scheduleEndTime).toDate() : null}
                  setDate={(value: string) => {
                    const temp = _.cloneDeep(editRentalSchedule!);

                    temp.scheduleEndTime = value;

                    setEditRentalSchedule(temp);
                  }}
                  minDate={moment(contract?.contractStartTime).toDate()}
                  maxDate={moment(contract?.contractEndTime).toDate()}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="flex-center">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>빌링스케줄</p>
          </div>
          <div className="contents-container__grid-contents">
            <div>
              <BaseButton onClick={() => gotoBillingScheduleDetailList()} title="이용료 빌링/연체 현황" className="color-white" />
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center font14">
              연체 요율(%)
              <BaseTooltip
                contents={""}
                tooltip={"기본 연 18% 일할 적용입니다. ‘계약체결’ 전까지 변경할 수 있습니다."}
                type={"normal"}
                className={"tooltip__trigger-icon"}
              />
            </div>
          </div>

          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <BaseButton
                title="연체 요율 관리"
                className="color-white"
                disabled={contract?.contractStep === "APPLY_RECEIVED" || contract?.contractStep === "APPLY_CANCEL"}
                onClick={() => setIsOverdueFeeOpen(true)}
              />
              <OverdueFeePopup
                isOpen={isOverdueFeeOpen}
                overdueChargeStandard={editRentalSchedule?.overdueChargeStandard!}
                overdueList={_.cloneDeep(editRentalSchedule?.scheduleOverdueList!)}
                onClick={(editedData: any) => {
                  setIsOverdueFeeOpen(false);
                  const temp = _.cloneDeep(editRentalSchedule!);
                  temp.overdueChargeStandard = editedData.overdueChargeStandard;
                  temp.scheduleOverdueList = editedData.overdueList.filter((obj: any) => obj.cmdType !== undefined);

                  setEditRentalSchedule(temp);
                }}
                onClose={() => setIsOverdueFeeOpen(false)}
                viewOnly={false}
              />
            </div>
          </div>
        </section>
      </div>

      <div className="flex-center">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>자동 결제/수납</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <>
                <BaseRadio
                  disabled={
                    contract?.contractStep === "APPLY_RECEIVED" ||
                    contract?.contractStep === "APPLY_CANCEL" ||
                    contract?.contractStep === "USE_COMPLETE" ||
                    contract?.contractStep === "TERMINATE_COMPLETE"
                  }
                  id={"isAutoBilling_true"}
                  name={"isAutoBilling"}
                  label="이용중"
                  value="true"
                  className="mr16"
                  checked={String(editRentalSchedule?.isAutoBilling) === "true" ? true : false}
                  onChange={() => {
                    const temp = _.cloneDeep(editRentalSchedule!);
                    if (editRentalSchedule?.regularPgcode !== "creditcard") {
                      if (detailData?.provider?.providerPopbill?.isVerifiedAccountInfo) {
                        temp.isAutoBilling = true;
                      } else {
                        temp.isAutoBilling = false;
                        openToast({
                          content: "해당 프로바이더의 팝빌 - 계좌조회 API 정보 검증에 실패하였습니다. 정산정보 메뉴에서 확인해 주세요.",
                          isSystemToast: true,
                        });
                      }
                    }
                    if (editRentalSchedule?.regularPgcode === "creditcard") {
                      if (
                        detailData?.provider?.providerPayletter?.isVerifiedAutoPaymentKey &&
                        detailData?.provider?.providerPayletter?.isVerifiedAutoSearchKey
                      ) {
                        temp.isAutoBilling = true;
                      } else {
                        temp.isAutoBilling = false;
                        openToast({
                          content: "해당 프로바이더의 페이레터 자동결제 인증 정보 검증에 실패하였습니다. 정산정보 메뉴에서 확인해 주세요.",
                          isSystemToast: true,
                        });
                      }
                    }

                    setEditRentalSchedule(temp);
                  }}
                />
                <BaseRadio
                  disabled={
                    contract?.contractStep === "APPLY_RECEIVED" ||
                    contract?.contractStep === "APPLY_CANCEL" ||
                    contract?.contractStep === "USE_COMPLETE" ||
                    contract?.contractStep === "TERMINATE_COMPLETE"
                  }
                  id={"isAutoBilling_false"}
                  name={"isAutoBilling"}
                  label="이용 안함"
                  value="false"
                  checked={String(editRentalSchedule?.isAutoBilling) === "false" ? true : false}
                  onChange={(e) => {
                    const temp = _.cloneDeep(editRentalSchedule!);

                    temp.isAutoBilling = false;

                    setEditRentalSchedule(temp);
                  }}
                />
              </>
            </div>
          </div>
        </section>
      </div>

      <div className="flex-center">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center font14">
              정기 청구일
              <BaseTooltip
                contents={""}
                tooltip={"정기 청구일에 맞춰 이용료 납부 요청이 발송됩니다. 청구일이 주말이나 공휴일인 경우, 가장 빨리 도래하는 영업일에 청구됩니다."}
                type={"normal"}
                className={"tooltip__trigger-icon"}
              />
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <div className="minmax100">
                <BaseSelect
                  isDisabled={disabledScheduleBillEdit.includes(String(contract?.contractStep))}
                  name={"payRegularDay"}
                  placeholder={""}
                  value={editRentalSchedule?.payRegularDay}
                  stateOptions={monthDays}
                  setStateValue={(value: number) => {
                    const temp = _.cloneDeep(editRentalSchedule!);

                    temp.payRegularDay = value;

                    setEditRentalSchedule(temp);
                  }}
                />
              </div>
              <div className="ml10 minmax140">
                <BaseSelect
                  isDisabled={disabledScheduleBillEdit.includes(String(contract?.contractStep))}
                  name={"payRegularMonth"}
                  placeholder={""}
                  value={editRentalSchedule?.payRegularMonth}
                  stateOptions={[
                    { label: "전월 기준", value: "PREV_MONTH" },
                    { label: "당월 기준", value: "CURR_MONTH" },
                    { label: "익월 기준", value: "NEXT_MONTH" },
                  ]}
                  setStateValue={(value: string) => {
                    const temp = _.cloneDeep(editRentalSchedule!);

                    temp.payRegularMonth = value;

                    setEditRentalSchedule(temp);
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="flex-center">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>결제 방법</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax140">
              <BaseSelect
                isDisabled={
                  contract?.contractStep === "APPLY_RECEIVED" ||
                  contract?.contractStep === "APPLY_CANCEL" ||
                  contract?.contractStep === "USE_COMPLETE" ||
                  contract?.contractStep === "TERMINATE_COMPLETE"
                }
                name={"regularPgcode"}
                placeholder="선택"
                value={String(editRentalSchedule?.regularPgcode)}
                stateOptions={
                  detailData?.product?.productType !== "TIME_COURT"
                    ? [
                        { label: "신용카드", value: "creditcard", isDisabled: false },
                        { label: "계좌이체", value: "banktransfer", isDisabled: false },
                      ]
                    : [
                        { label: "신용카드", value: "creditcard", isDisabled: false },
                        {
                          label: "현금(가상계좌)",
                          value: "virtualaccount",
                          isDisabled: false,
                        },
                      ]
                }
                setStateValue={(value: string) => {
                  const temp = _.cloneDeep(editRentalSchedule!);
                  if (value === "creditcard") {
                    if (
                      detailData?.provider?.providerPayletter?.isVerifiedAutoPaymentKey &&
                      detailData?.provider?.providerPayletter?.isVerifiedAutoSearchKey
                    ) {
                      temp.regularPgcode = value;
                    } else {
                      openToast({
                        content: "해당 프로바이더의 페이레터 자동결제 인증 정보 검증에 실패하였습니다. 정산정보 메뉴에서 확인해 주세요.",
                        isSystemToast: true,
                      });
                    }
                  } else {
                    if (detailData?.provider?.providerAccountList.find((ac: any) => ac.accountType === "RENT_ACCOUNT")) {
                      temp.regularPgcode = value;
                    } else {
                      openToast({
                        content: "해당 프로바이더의 계좌등록 여부 검증에 실패하였습니다. 정산정보 메뉴 임대료 계좌를 등록해 주세요.",
                        isSystemToast: true,
                      });
                    }
                  }

                  setEditRentalSchedule(temp);
                }}
              />
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center font14">
              납부 기한
              <BaseTooltip
                contents={""}
                tooltip={
                  "기본값은 14일이며, 필요에 따라 변경할 수 있습니다. 납부 기한이 주말이나 공휴일인 경우, 가장 빨리 도래하는 영업일에 청구됩니다."
                }
                type={"normal"}
                className={"tooltip__trigger-icon"}
              />
            </div>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <div className="minmax90 font14 mr0">청구일 기준 +</div>
              <div className="minmax100 ml0 flex-row flex-center-start">
                <BaseInput
                  readonly={disabledScheduleBillEdit.includes(contract?.contractStep!)}
                  name={"payDueDays"}
                  placeholder={""}
                  value={editRentalSchedule?.payDueDays}
                  onChange={(value: number) => {
                    const temp = _.cloneDeep(editRentalSchedule!);

                    temp.payDueDays = value;

                    setEditRentalSchedule(temp);
                  }}
                />
              </div>
              <div className="minmax80 font14 ml5">일</div>
            </div>
          </div>
        </section>
      </div>
      <div className="flex-center">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>입금자명</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <div className="minmax160">
                <BaseInput
                  readonly={
                    contract?.contractStep === "APPLY_CANCEL" ||
                    contract?.contractStep === "TERMINATE_COMPLETE" ||
                    (detailData?.product?.productType !== "TIME_COURT" && contract?.contractStep === "APPLY_CONFIRM") ||
                    (detailData?.product?.productType !== "TIME_COURT" && contract?.contractStep === "USE_COMPLETE")
                  }
                  // maxLength={7}
                  name={"accountInName"}
                  value={editRentalSchedule?.accountInName}
                  onChange={(value: string) => {
                    const maxByte = 14;
                    var strValue = value;
                    var strLen = strValue.length;
                    var totalByte = 0;
                    // var len = 0;
                    var oneChar = "";

                    for (var i = 0; i < strLen; i++) {
                      oneChar = strValue.charAt(i);
                      if (escape(oneChar).length > 4) {
                        totalByte += 2;
                      } else {
                        totalByte++;
                      }

                      // 입력한 문자 길이보다 넘치면 잘라내기 위해 저장
                      // if (totalByte <= maxByte) {
                      //   len = i + 1;
                      // }
                    }

                    // 넘어가는 글자는 자른다.
                    if (totalByte > maxByte) {
                      openToast({ content: maxByte / 2 + "자를 초과 입력 할 수 없습니다.", isSystemToast: true });
                    } else {
                      const temp = _.cloneDeep(editRentalSchedule!);

                      temp.accountInName = value;

                      setEditRentalSchedule(temp);
                    }
                  }}
                  // errorText={errorAccountInName}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : (
    <div>
      <div className="flex-center">
        <BaseSection title={"결제 상태"} children={<PaymentStatusView value={s2Rounds !== null ? s2Rounds.bill!.billPayStatus! : ""} />} />
        <BaseSection
          title={"결제 일시"}
          children={
            <p>
              {s2Rounds !== null
                ? s2Rounds.receiptBundle!.lastTrDateTime
                  ? moment(s2Rounds.receiptBundle!.lastTrDateTime).format(ViewYmdFormat.FULL)
                  : "-"
                : "-"}
            </p>
          }
        />
      </div>
      <div className="flex-center">
        <BaseSection
          title={"결제 방법"}
          children={<p>{s2Rounds !== null ? (s2Rounds.bill!.pgcode === "creditcard" ? "신용카드" : "가상계좌") : "-"}</p>}
        />
        <BaseSection
          title={"결제 기한"}
          children={
            <div className="minmax260 ml5">
              <BaseDatePicker
                readonly={!disabledBeforeContractAccept.includes(String(contract?.contractStep))}
                name={"billDueDate"}
                type="date"
                selectedDate={s2Rounds?.bill?.billDueDate ? moment(s2Rounds?.bill?.billDueDate).toDate() : null}
                setDate={(value: string) => {
                  const temp = _.cloneDeep(s2Rounds!);

                  temp.bill.billDueDate = moment(value).format(YmdFormat.YYYY_MM_DD) + "T23:59:59.999+09:00";

                  setS2Rounds(temp);
                }}
              />
            </div>
          }
        />
      </div>
      <div className="flex-center">
        {s2Rounds !== null &&
          s2Rounds!.bill!.pgcode !== "creditcard" &&
          s2Rounds.bill!.paymentList?.map((data: any) => {
            return <BaseSection title={"ㄴ 부가 정보"} children={<p>{data.virtualAccount.bankName + " " + data.virtualAccount.accountNo}</p>} />;
          })}
        <BaseSection
          title={"결제 금액"}
          children={
            <p>
              {s2Rounds !== null
                ? s2Rounds.receiptBundle!.receiptTotalAmount
                  ? moment(s2Rounds.receiptBundle!.receiptTotalAmount).format(ViewYmdFormat.FULL)
                  : "-"
                : "-"}
            </p>
          }
        />
        {s2Rounds !== null && s2Rounds!.bill!.pgcode !== "creditcard" && (
          <BaseSection
            title={"계산서 작성일자"}
            children={
              <div className="minmax260 ml5">
                <BaseDatePicker
                  readonly={s2Rounds.taxInvoiceList.length > 0}
                  name={"scheduleEndTime"}
                  type="date"
                  selectedDate={s2Rounds?.bill?.taxInvoiceIssueDate ? moment(s2Rounds?.bill?.taxInvoiceIssueDate).toDate() : null}
                  setDate={(value: string) => {
                    const temp = _.cloneDeep(s2Rounds!);

                    temp.bill.taxInvoiceIssueDate = moment(value).format(YmdFormat.WITH_TIME_ZONE);

                    setS2Rounds(temp);
                  }}
                />
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};
export default BillingScheduleEdit;
