import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { getProductAsync } from "src/api/access/ac-api";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync, getProductListAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { PageMeta } from "src/api/public-types";
import { BaseInput, BaseModal, BasePagination, BaseRadio, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import Link from "src/components/PartnerLink";
import ProductStatusLabel from "src/components/ProductStatusLabel";
import ProductTypeChangeLabel from "src/components/ProductTypeChangeLabel";
import useApiLoading from "src/hooks/useApiLoading";
import pagePath from "src/pagePath.json";
import { ProductStatusT } from "../product/product-types";
import ProductDepositCell from "./columns/ProductDepositCell";
import ProductRentalCostCell from "./columns/ProductRentalCostCell";
import useContractApi from "../contract/hooks/useContractApi";

const enum ProductKeisEnum {
  id = "id",
  providerId = "프로바이더",
  productType = "상품유형",
  productName = "상품명",
  deposit = "보증금",
  earnest = "계약금",
  maxNums = "계정 최대 인원",
  costType = "요금타입",
  isDesk = "좌석 사용 여부",
  isMeetingRoom = "회의실 사용 여부",
  isRefreshRoom = "편의시설 사용여부",
  rentalCostList = "이용료",
  buildingList = "건물",
  maxLeasePeriod = "최대 이용 개월",
  minLeasePeriod = "최소 이용 개월",
}

const checkProductKeis: any = [
  { key: "providerId", text: ProductKeisEnum.providerId, check: 0 || null },
  { key: "productType", text: ProductKeisEnum.productType, check: "PRODUCT_UNRECOGNIZED" },
  { key: "productName", text: ProductKeisEnum.productName, check: "" },
  { key: "deposit", text: ProductKeisEnum.deposit, check: 0 || null },
  { key: "earnest", text: ProductKeisEnum.earnest, check: 0 || null },
  { key: "maxNums", text: ProductKeisEnum.maxNums, check: 0 || null },
  { key: "costType", text: ProductKeisEnum.costType, check: "COST_UNRECOGNIZED" },
  { key: "maxLeasePeriod", text: ProductKeisEnum.maxLeasePeriod, check: null },
  { key: "minLeasePeriod", text: ProductKeisEnum.minLeasePeriod, check: null },
  { key: "isDesk", text: ProductKeisEnum.isDesk, check: null },
  { key: "isMeetingRoom", text: ProductKeisEnum.isMeetingRoom, check: null },
  { key: "isRefreshRoom", text: ProductKeisEnum.isRefreshRoom, check: null },
  { key: "rentalCostList", text: ProductKeisEnum.rentalCostList, check: [] },
  { key: "buildingList", text: ProductKeisEnum.buildingList, check: [] },
];

const checkProductKeisS2: any = [
  { key: "providerId", text: ProductKeisEnum.providerId, check: 0 || null },
  { key: "productType", text: ProductKeisEnum.productType, check: "PRODUCT_UNRECOGNIZED" },
  { key: "productName", text: ProductKeisEnum.productName, check: "" },
  { key: "isDesk", text: ProductKeisEnum.isDesk, check: null },
  { key: "isMeetingRoom", text: ProductKeisEnum.isMeetingRoom, check: null },
  { key: "isRefreshRoom", text: ProductKeisEnum.isRefreshRoom, check: null },
  { key: "maxNums", text: ProductKeisEnum.maxNums, check: 0 || null },
  { key: "buildingList", text: ProductKeisEnum.buildingList, check: [] },
];

const checkRentalCostList = [
  { key: "metaItem", text: "아이템타입", check: "" },
  { key: "value1", text: "기본값1", check: "" },
];
const checkBuildingList = [
  { key: "buildingName", text: "건물이름", check: "" },
  { key: "mgmtOfficeId", text: "관리처", check: "" },
  { key: "mgmtProviderId", text: "정산정보_관리처", check: "" },
];
const checkBuildingFloorList = [
  { key: "floorNum", text: "층 번호", check: 0 },
  { key: "floorName", text: "층 명칭", check: "" },
];
const checkBuildingRoomList = [
  { key: "roomNum", text: "호실", check: 0 },
  { key: "roomName", text: "호실 명칭", check: "" },
];
const checkBuildingCommonFacility = [
  { key: "facilityName", text: "시설명", check: "" },
  { key: "facilityMaxPeopleNums", text: "시설정원(수)", check: 0 },
];

const checkDeskList = [{ key: "facilityName", text: "시설명", check: "" }];

const columnHeader: any = [
  {
    Header: "선택",
    accessor: "isSelected",
    // sticky: "left",
    width: 60,
    Cell: ({ value, row, setSelected }: any) => {
      const changeTrigger = useCallback(() => {
        setSelected(row.original);
      }, [row.original, setSelected]);
      return (
        <div>
          <BaseRadio id={`selector${row.original.id}`} name={"isSelected"} onChange={changeTrigger} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    // sticky: "left",
    width: 80,
  },
  // {
  //   Header: "이미지",
  //   accessor: "image",
  //   Cell: (props: Cell<ProductModel>) => <ImagePathCell {...props} />,
  //   width: 100,
  // },
  {
    Header: "상품명",
    accessor: "productName",
    width: 120,
    Cell: (props: Cell<ProductModel>) => {
      const detailPath = `${pagePath.product.detail.replace(":id", `${props.row.original.id}`)}`;

      return (
        <div className="w-100 text-left">
          <Link to={detailPath} target={"_blank"} className="text-underline">
            <BaseTooltip contents={props?.value || "-"} />
          </Link>
        </div>
      );
    },
  },
  {
    Header: "상품유형",
    accessor: "productType",
    width: 80,
    Cell: (props: Cell<ProductModel>) => <ProductTypeChangeLabel productType={props.value} />,
  },
  {
    Header: "보증금",
    accessor: "saleDeposit",
    Cell: (props: Cell<ProductModel>) => <ProductDepositCell {...props} />,
    width: 100,
  },
  {
    Header: "이용료",
    accessor: "rentalCost",
    Cell: (props: Cell<ProductModel>) => <ProductRentalCostCell {...props} />,
    width: 120,
  },
  {
    Header: "주소",
    accessor: "address",
    width: 160,
    Cell: (props: Cell<ProductModel>) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={props?.value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName", // api 스펙에 없음
    width: 120,
    Cell: (props: Cell<ProductModel>) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={props?.value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "공개 여부",
    accessor: "status",
    width: 120,
    Cell: (props: Cell<ProductModel>) =>
      props.row.original.status ? <ProductStatusLabel status={props.row.original.status as ProductStatusT} /> : "-",
  },
];
type Props = {
  isOpen: boolean;
  partnerId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchSpaceProductPopup = (props: Props) => {
  const { getProviderData } = useContractApi();
  const { isApiLoading } = useApiLoading();
  const [selected, setSelected] = useState<any>();

  const [products, setProducts] = useState<ProductModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getProductList } = useApiOperation(getProductListAsync);
  const [keyword, setKeyword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  const [errorList, setErrorList] = useState<any>([]);

  const [errorFlag, setErrorFlag] = useState(false);

  const { executeAsync: getProduct } = useApiOperation(getProductAsync, {
    noHandleError: false,
  });

  // 공간상품 상세 조회 api
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  const fetchProduct = useCallback(
    async (id: number) => {
      return await getProduct({ id });
    },
    [getProduct],
  );
  const [params, setParams] = useState<any>({
    page: 0,
    size: 20,

    searchValue: "",
    // status: 'ENABLED',
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columnHeader,
      data: products,
      setSelected,
    },

    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );
  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  const checkBuildingData = useCallback((check: any, buildingList: any, results: any) => {
    buildingList.forEach((build: any) => {
      for (const [key, value] of Object.entries(build)) {
        const find: any = check.find((fnd: any) => fnd.key === key);

        if (find && find.check === value) {
          const result: any = { key, text: "", validation: true, sub: [] };
          result.text = find.text;
          result.validation = false;
          results.push(result);
        }
      }
      if (build.buildingFloorList) {
        if (build.buildingFloorList.length === 0) {
          const result: any = { key: "build.buildingFloorList", text: "건물 층 정보", validation: true, sub: [] };
          result.validation = false;
          results.push(result);
        } else {
          results = findArrayData(checkBuildingFloorList, build.buildingFloorList, results, "건물-층-");

          build.buildingFloorList.map((floor: any) => {
            results = findArrayData(checkBuildingRoomList, floor.buildingRoomList, results, "건물-층-호-");
          });
        }
      }
      if (build.buildingCommonFacility) {
        if (build.buildingCommonFacility.meetingRoomList.length > 0) {
          results = findArrayData(checkBuildingCommonFacility, build.buildingCommonFacility.meetingRoomList, results, "회의실-");
        }
        if (build.buildingCommonFacility.refreshRoomList.length > 0) {
          results = findArrayData(checkBuildingCommonFacility, build.buildingCommonFacility.refreshRoomList, results, "편의시설-");
        }
        if (build.buildingCommonFacility.deskSpace.deskGroupList.length > 0) {
          results = findArrayData(checkDeskList, build.buildingCommonFacility.deskSpace.deskGroupList, results, "좌석-");
        }
      }
    });

    return results;
  }, []);

  const findArrayData = (check: any, arr: any, rtnArr: any, addText: string) => {
    arr.forEach((datas: any) => {
      for (const [key, value] of Object.entries(datas)) {
        const find: any = check.find((fnd: any) => fnd.key === key);

        if (find && find.check === value) {
          const result: any = { key, text: addText + find.text, validation: true, sub: [] };
          result.validation = false;
          rtnArr.push(result);
        }
      }
    });

    rtnArr = _.uniqBy(rtnArr, "text");
    return rtnArr;
  };

  const checkProductBasic = useCallback(
    (product: any) => {
      let results: any = [];
      if (product.productType !== "TIME_COURT") {
        for (const [key, value] of Object.entries(product)) {
          const find = checkProductKeis.find((fnd: any) => fnd.key === key);
          const result: any = { key, text: "", validation: true, sub: [] };
          if (find) {
            result.text = find.text;
            let findCheck = find.check;

            if (typeof findCheck === "string") {
              if (findCheck === value) {
                result.validation = false;
              }
            }
            if (typeof findCheck === "object") {
              const vl: any = value;
              if (Array.isArray(findCheck)) {
                if (findCheck.length === vl.length) {
                  console.log(findCheck.length === vl.length, findCheck.length, vl.length);
                  result.validation = false;
                } else {
                  if (key === "rentalCostList") {
                    if (vl.length === 0) {
                      result.validation = false;
                      results.push(result);
                    } else {
                      results = findArrayData(checkRentalCostList, vl, results, "상품-이용료");
                    }
                  }
                  if (key === "buildingList") {
                    if (vl.length === 0) {
                      result.validation = false;
                      results.push(result);
                    } else {
                      results = checkBuildingData(checkBuildingList, vl, results);
                    }
                  }
                }
              } else {
                if (findCheck === null && value === findCheck) {
                  result.validation = false;
                }
              }
            }
          }

          results.push(result);
        }

        return results.filter((pc: any) => pc.validation === false);
      } else {
        for (const [key, value] of Object.entries(product)) {
          const find = checkProductKeisS2.find((fnd: any) => fnd.key === key);
          const result: any = { key, text: "", validation: true, sub: [] };
          if (find) {
            result.text = find.text;
            let findCheck = find.check;

            if (typeof findCheck === "string") {
              if (findCheck === value) {
                result.validation = false;
              }
            }
            if (typeof findCheck === "object") {
              const vl: any = value;
              if (Array.isArray(findCheck)) {
                if (findCheck.length === vl.length) {
                  console.log(findCheck.length === vl.length, findCheck.length, vl.length);
                  result.validation = false;
                } else {
                  if (key === "buildingList") {
                    if (vl.length === 0) {
                      result.validation = false;
                      results.push(result);
                    } else {
                      results = checkBuildingData(checkBuildingList, vl, results);
                    }
                  }
                }
              } else {
                if (findCheck === null && value === findCheck) {
                  result.validation = false;
                }
              }
            }
          }

          results.push(result);
        }
        return results;
      }
    },
    [checkBuildingData],
  );

  const onClickData = useCallback(async () => {
    setMessage("");

    const response: any = await getProductDetail({ productId: selected.id });
    const product = response.data.data.content;
    console.log("product", product);
    const productCheck: any = checkProductBasic(product);

    if (product.productType === "TIME_COURT") {
      const providerDetail: any = await getProviderData(product.providerId);
      console.log("providerDetail", providerDetail);
      const payletter = providerDetail.providerPayletter;
      const popbill = providerDetail.providerPopbill;

      if (!payletter.isVerifiedPaymentKey || !payletter.isVerifiedSearchKey || !popbill.isVerifiedCashbill || !popbill.isVerifiedTaxInvoice) {
        setError2(true);
        return;
      }
    }

    const checker = productCheck.filter((pc: any) => pc.validation === false).length > 0 ? true : false;
    if (checker === true) {
      setErrorList(productCheck);
      setErrorFlag(true);
      return;
    }

    if (props.onClick && selected && selected.productType !== "PRODUCT_UNRECOGNIZED") {
      // const getAc: any = await fetchProduct(selected.id);
      // console.log("getAc", getAc);
      // if (getAc.status >= 200 && getAc.status <= 299 && getAc.data.data.product !== null) {
      props.onClick(selected);
      // } else {
      //   setMessage("해당 상품의 액세스 그룹 설정이 필요합니다.");
      //   setError(true);
      //   return;
      // }
    }
  }, [checkProductBasic, getProductDetail, props, selected]);

  const closeData = useCallback(async () => {
    if (props.onClose) props.onClose(selected);
  }, [props]);

  const callList = useCallback(
    async (param: any) => {
      const response: any = await getProductList(param);
      if (response.status > 199 && response.status < 300) {
        // console.log("response?.data?.data?.content.length", response?.data?.data?.content);
        for (let i = 0; i < response?.data?.data?.content.length; i++) {
          // response.data.data.content[i].isSelected = false;
          response.data.data.content[i] = Object.assign(response.data.data.content[i], {
            isSelected: false,
          });
          // console.log("response.data.data.content[i]", response.data.data.content[i].providerId);
        }
        setProducts(response?.data?.data?.content || []);
        setPageMeta(response?.data?.meta?.pageMeta);
      }
    },
    [getProductList],
  );

  useEffect(() => {
    if (props.isOpen) {
      let prs = _.cloneDeep(params);
      prs.page = 0;
      prs.keyword = "";
      if (props.partnerId !== undefined) {
        prs.partnerId = props.partnerId;
      }

      setKeyword("");
      callList(prs);
      setParams(prs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <BaseAbstractModal
      className="dialog-modal"
      isOpen={props.isOpen}
      // btnRightTitle="선택"
      // btnLeftTitle="취소"
      // onClick={() => onClickData()}
      // onClose={props.onClose}
    >
      <>
        <section className="base-abstract-modal__title">
          <div className="flex-row flex-center-between w-100">
            <h1>상품 선택</h1>
          </div>

          <div className="minmax240 text-left">
            <BaseInput
              type="text"
              value={keyword || ""}
              placeholder="검색어를 입력해주세요"
              onKeyUp={() => {
                callList({ ...params, ...{ keyword }, ...{ page: 0 } });
                setParams({ ...params, ...{ keyword }, ...{ page: 0 } });
              }}
              onChange={(value: string) => {
                setKeyword(value);
              }}
              onSearchClick={() => {
                callList({ ...params, ...{ keyword }, ...{ page: 0 } });
                setParams({ ...params, ...{ keyword }, ...{ page: 0 } });
              }}
              onClearClick={() => {
                setKeyword("");
                setParams({ ...params, ...{ keyword: "" }, ...{ page: 0 } });
              }}
            />
          </div>
        </section>
        {/* table */}
        <section className="base-abstract-modal__contents">
          <div {...getTableProps()} className="base-table view-data-table sticky px30">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div>
                {rows.length === 0 && (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="px30">
          <BasePagination
            pageIndex={params.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            currentSize={Number(params.size) || 20}
            sizeOption={(sizeValue) => {
              setParams({ ...params, size: sizeValue });
              callList({ ...params, size: sizeValue });
            }}
            totalElements={pageMeta?.totalElements || 0}
            goPage={(page: number) => {
              setParams({ ...params, ...{ page } });
              callList({ ...params, ...{ page } });
            }}
            children={
              <div className="flex-center">
                <button className="base-btn color-white mr10" onClick={() => closeData()}>
                  취소
                </button>
                <button className="base-btn" onClick={() => onClickData()}>
                  완료
                </button>
              </div>
            }
          />
        </section>
        <BaseModal isOpen={error} btnRightTitle={"확인"} onClick={() => setError(false)} title={message}></BaseModal>
        <BaseModal isOpen={error2} btnRightTitle={"확인"} onClick={() => setError2(false)} title={"해당 상품으로 계약을 생성할 수 없습니다."}>
          <p>{"정산정보 메뉴에서 해당 프로바이더의 팝빌 인증정보 검증을 실행해 주세요."}</p>
        </BaseModal>
        <BaseModal isOpen={errorFlag} btnRightTitle={"확인"} onClick={() => setErrorFlag(false)} title={"상품을 선택할 수 없습니다."}>
          <p className="mb10 font14">상품 필수 데이터가 작성되지 않았습니다.</p>
          <ul>
            {errorList.map((error: any, index: number) => {
              if (error.validation === false) {
                return (
                  <li key={index}>
                    {error.text}{" "}
                    {error.sub.length > 0 && (
                      <ul>
                        {error.sub.map((sub: any, idx: number) => {
                          <li key={idx}>{sub.text}</li>;
                        })}
                      </ul>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </BaseModal>
      </>
    </BaseAbstractModal>
  );
};

export default SearchSpaceProductPopup;
