import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { getContractDetailBasicInfo } from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta, Select } from "src/api/public-types";
import { getVisitorsAsync } from "src/api/visitor/visitor-api";
import { VisitorListModel } from "src/api/visitor/visitor-types";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseModal, BaseSelect, BaseTable } from "src/components";
import RangeDatepicker from "src/components/RangeDatepicker";
import ContractSelectModal from "src/components/contract/ContractSelectModal";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useQueryParams } from "src/hooks/useQueryParams";
import PagePath from "src/pagePath.json";
import { downloadExcel } from "src/utils/common-util";
import { INVITEABLE_CONTRACT_STEP, VISITOR_STATUS_MAP, VISIT_PURPOSE_MAP, VISIT_TYPE_MAP } from "../constants";
import { formatVisitorMobileNumber } from "../utils";
import { Modal } from "../visitor-types";
import ExcelDownloadButton from "./ExcelDownloadButton";
import { columns } from "./columns/Columns";

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  visitStatus?: string;
  searchType?: string;
  searchValue?: string;
  partnerId?: string;
  searchStartTime?: string;
  searchEndTime?: string;
  visitType?: string;
  visitPurpose?: string;
  contractId?: string;
  contractApplyNumber?: string;
  contractStep?: ContractStep;
};

// INVITE_REGIST: "초대",
// SELF_REGIST: "셀프등록",
const visitTypeOptions: Select[] = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "초대",
    value: "INVITE_REGIST",
  },
  {
    label: "셀프등록",
    value: "SELF_REGIST",
  },
];

// 방문 목적 options
const visitPurposeOptions: Select[] = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "투어/상담",
    value: "TOUR_COUNSELING",
  },
  {
    label: "지인방문",
    value: "FRIEND_VISIT",
  },
  {
    label: "택배",
    value: "PACKAGE_DELIVERY",
  },
  {
    label: "우편",
    value: "MAIL_DELIVERY",
  },
  {
    label: "배달",
    value: "ETC_DELIVERY",
  },
];

// 방문상태 options
const visitStatusOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "사용", value: "VISIT_USE" },
  { label: "삭제", value: "VISIT_DELETE" },
  { label: "종료", value: "VISIT_CLOSE" },
];

// 검색대상 options
const searchTypeOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "방문신청번호", value: "VISIT_APPLY_NUMBER" },
  { label: "방문자 명", value: "VISITOR_NAME" },
  { label: "방문자 휴대폰", value: "VISITOR_MOBILE_NUMBER" },
  { label: "방문자 이메일", value: "VISITOR_EMAIL" },
  { label: "방문자 차량번호", value: "VISITOR_CAR_NUMBER" },
  { label: "공간상품id", value: "SPACE_PRODUCT_ID" },
  { label: "공간상품명", value: "SPACE_PRODUCT_NAME" },
];

// 정렬기능 숨김
const disabledSortHeaders: string[] = ["visitorName", "visitorMobileNumber", "visitStatus", "createdDate", "createdBy", "modifiedDate"];

/* 
  방문자 > 목록화면
*/

const VisitorList = () => {
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const { isAuthority } = usePartnerAuthority();

  const { queryParams, queryParamsNavigate } = useQueryParams<QueryParams>({
    page: 0,
    size: 20,
    sort: {
      orders: [{ property: "createdDate", direction: "DESC" }],
    },
  });

  const [searchInput, setSearchInput] = useState({
    searchType: "",
    searchValue: "",
  });

  const { executeAsync: getVisitors } = useApiOperation(getVisitorsAsync);
  const { executeAsync: getContractDetailBasicInfoAsync } = useApiOperation(getContractDetailBasicInfo);

  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  const [contractSelectModal, setContractSelectModal] = useState<Modal>({
    isOpen: false,
  });

  const [visitors, setVisitors] = useState<VisitorListModel[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  console.log("visitors :>> ", visitors);

  useEffect(() => {
    // queryParams 기준 방문자 리스트 호출
    (async () => {
      const result = await getVisitors(queryParams);
      console.log("getVisitorsqueryParams API call :>> ", queryParams);

      if (result.status !== 200) return;

      setVisitors(result.data.data.content);
      setPageMeta(result.data.meta.pageMeta);
    })();
  }, [queryParams]);

  useEffect(() => {
    // queryParams 검색 옵션, 검색어 세팅
    setSearchInput({ searchType: queryParams.searchType ?? "", searchValue: queryParams.searchValue ?? "" });
  }, [queryParams.searchType, queryParams.searchValue]);

  useEffect(() => {
    // queryParams 기준 날짜 세팅
    const dump = _.cloneDeep(dateRange);
    if (queryParams?.searchStartTime !== undefined && queryParams?.searchStartTime !== "") {
      dump[0] = moment(queryParams?.searchStartTime).toDate();
    } else {
      dump[0] = null;
    }
    if (queryParams?.searchEndTime !== undefined && queryParams?.searchEndTime !== "") {
      dump[1] = moment(queryParams?.searchEndTime).toDate();
    } else {
      dump[1] = null;
    }
    setDateRange(dump);
  }, [queryParams.searchEndTime, queryParams.searchStartTime]);

  // 날짜 검색
  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    let start = "";
    let end = "";

    if (dateRange[0] !== null) {
      start = moment(dateRange[0]).format("YYYY-MM-DD") + "T00:00:00.000+09:00";
    }
    if (dateRange[1] !== null) {
      end = moment(dateRange[1]).format("YYYY-MM-DD") + "T23:59:59.999+09:00";

      queryParamsNavigate({ ...queryParams, searchStartTime: start, searchEndTime: end });
    }
  };

  const goFormPage = (contractApplyNumber: string) =>
    navigate(
      PagePath.visitor.add.replace(":contractManageId", contractApplyNumber).replace(":contractId", queryParams?.contractId?.toString() || ""),
    );

  const clickVisitorInvitation = async () => {
    const contractApplyNumber = queryParams?.contractApplyNumber;
    let contractStep = queryParams?.contractStep;

    if (!contractApplyNumber) {
      setAlertModal({ isOpen: true, message: "신청번호를 선택해주세요." });
      return;
    }

    if (!contractStep) {
      // 신청계약을 통해 접근한 경우 API 요청 후 계약 상태 체크
      const contract = await getContractDetailBasicInfoAsync({ id: Number(queryParams?.contractId) }).then((res) => res?.data?.data);
      contractStep = contract.contractStep as ContractStep;
    }

    if (!contractStep || !INVITEABLE_CONTRACT_STEP.includes(contractStep as ContractStep)) {
      setAlertModal({ isOpen: true, message: "초대할 수 없는 계약 상태입니다." });
      return;
    }

    goFormPage(contractApplyNumber);
  };

  const handleClickExcelDownloadButton = async () => {
    const params: any = {
      ...queryParams,
      searchStartTime: queryParams.searchStartTime ? moment(queryParams.searchStartTime).format("YYYY-MM-DD") + "T00:00:00.000+09:00" : undefined,
      searchEndTime: queryParams.searchEndTime ? moment(queryParams.searchEndTime).format("YYYY-MM-DD") + "T23:59:59.999+09:00" : undefined,
      searchType: queryParams.searchType,
      searchValue: queryParams.searchValue,
      sort: { orders: pageMeta?.pageRequest?.sort?.orders },
    };
    const sizeCheckParam = _.cloneDeep(params);
    sizeCheckParam.page = 0;
    sizeCheckParam.size = 1;
    const result = await getVisitors(sizeCheckParam);

    const { meta } = result.data;
    let arrData: any = [];

    const fixedCallSize = 100;
    if (meta?.pageMeta?.totalElements! > 0) {
      let callSize = Math.floor(meta?.pageMeta?.totalElements! / fixedCallSize);
      const dvSize = meta?.pageMeta?.totalElements! % fixedCallSize;
      // console.log("dv", callSize, dvSize);
      if (dvSize > 0) {
        callSize = callSize + 1;
      }
      // console.log("callSize", callSize, dvSize);

      let callList: any = [];
      for (let i = 0; i <= callSize; i++) {
        sizeCheckParam.page = i;
        sizeCheckParam.size = fixedCallSize;
        callList.push(
          new Promise((resolve, reject) => {
            resolve(getVisitors(sizeCheckParam));
          }),
        );
      }
      const result2: any = await Promise.all(callList);
      result2.forEach((response: any) => {
        arrData = [...arrData, ...response.data.data.content];
      });
    }
    if (arrData.length === 0) {
      setAlertModal({ isOpen: true, message: "조회된 데이터가 없습니다." });
      return;
    }

    const mappedData = arrData.map((item: any) => ({
      visitApplyNumber: item.visitApplyNumber ?? "-", // 신청번호
      visitStatus: item.visitStatus ? VISITOR_STATUS_MAP[item.visitStatus as keyof typeof VISITOR_STATUS_MAP] : "-", //상태
      visitType: item.visitType ? VISIT_TYPE_MAP[item.visitType as keyof typeof VISIT_TYPE_MAP] : "-", // 방문 유형
      visitPurpose: item.visitPurpose ? VISIT_PURPOSE_MAP[item.visitPurpose as keyof typeof VISIT_PURPOSE_MAP] : "-", // 방문목적
      visitorName: item.visitType === "SELF_REGIST" ? "-" : item.visitorName, // 방문자
      visitorMobileNumber: formatVisitorMobileNumber(item.visitorMobileNumber) ?? "-", // 방문자 번호
      visitStartTime: moment(item.visitStartTime).format("YYYY-MM-DD HH:mm") ?? "-", // 시작
      visitEndTime: moment(item.visitEndTime).format("YYYY-MM-DD HH:mm") ?? "-", // 종료
      contractApplyNumber: item.contractApplyNumber ?? "-", //신청번호
      spaceProductId: item.spaceProductId ?? "-", // 공간번호
      spaceProductName: item.spaceProductName ?? "-", // 공간명
      createdDate: moment(item.createdDate).format("YYYY-MM-DD HH:mm") ?? "-", // 생성일
      createdBy: item.createdBy ?? "-", // 등록 id
      modifiedDate: moment(item.modifiedDate).format("YYYY-MM-DD HH:mm") ?? "-", // 수정일
    }));

    try {
      const fileName =
        queryParams.searchStartTime && queryParams.searchEndTime
          ? `ctrl.room_방문자목록_${moment(queryParams.searchStartTime).format("YYYYMMDD")}~${moment(queryParams.searchEndTime).format(
              "YYYYMMDD",
            )}_${moment().format("YYYYMMDDHHmmss")}`
          : `ctrl.room-방문자목록-전체_${moment().format("YYYYMMDDHHmmss")}`;

      console.log("mappedData :>> ", mappedData);

      await downloadExcel({
        data: mappedData,
        fileName,
        header: columns.map((column) => column.Header),
      });

      setAlertModal({ isOpen: true, message: "엑셀 다운로드가 완료되었습니다." });
    } catch (error) {
      setAlertModal({ isOpen: true, message: "엑셀 다운로드에 실패하였습니다." });
    }
  };

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>지정검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={queryParams?.contractApplyNumber}
                    placeholder="신청번호"
                    onClick={() => setContractSelectModal({ isOpen: true })}
                    onClearClick={() => queryParamsNavigate({ ...queryParams, contractId: "", contractApplyNumber: "", contractStep: "" as any })}
                    readonly
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax201">
                  <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
                </div>
              </div>
              <div className="left-area__contents">
                <div className="minmax120 mr8">
                  <BaseSelect
                    placeholder="상태"
                    value={queryParams.visitStatus}
                    stateOptions={visitStatusOptions}
                    setStateValue={(visitStatus: string) => {
                      queryParamsNavigate({
                        ...queryParams,
                        visitStatus: visitStatus,
                      });
                    }}
                  />
                </div>
                <div className="minmax320 mr16">
                  <BaseInputWithSearch
                    type="text"
                    selectValue={searchInput.searchType}
                    inputValue={searchInput.searchValue}
                    stateOptions={searchTypeOptions}
                    setStateValue={(searchType: string) => {
                      setSearchInput((prev) => ({ ...prev, searchType: searchType }));
                    }}
                    onChange={(searchValue: string, e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchInput((prev) => ({ ...prev, searchValue: searchValue }))
                    }
                    onKeyUp={() => queryParamsNavigate({ ...queryParams, searchType: searchInput.searchType, searchValue: searchInput.searchValue })}
                    onSearchClick={() =>
                      queryParamsNavigate({ ...queryParams, searchType: searchInput.searchType, searchValue: searchInput.searchValue })
                    }
                    onClearClick={() => queryParamsNavigate({ ...queryParams, searchType: "", searchValue: "" })}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">{isAuthority("w") && <BaseButton title="+ 방문자 초대" onClick={clickVisitorInvitation} />}</div>
        </div>

        {visitors && (
          <BaseTable
            data={visitors}
            columns={columns}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            totalElements={pageMeta?.totalElements || 0}
            currentSize={Number(pageMeta?.pageRequest?.size) || 20}
            sizeOption={(size) => queryParamsNavigate({ ...queryParams, page: 0, size: size })}
            goPage={(page: number) => queryParamsNavigate({ ...queryParams, page: page, size: pageMeta?.pageRequest?.size })}
            disabledSortHeaders={disabledSortHeaders}
            orders={pageMeta?.pageRequest.sort?.orders}
            setOrders={(orders: Order[]) => queryParamsNavigate({ ...queryParams, sort: { orders: orders } })}
            children={<ExcelDownloadButton onClick={handleClickExcelDownloadButton}>엑셀받기</ExcelDownloadButton>}
          />
        )}
      </div>

      {/* 확인버튼만 있는 alert 모달 */}
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
          onClose={() => setAlertModal({ isOpen: false })}
          title={alertModal.message}
        />
      )}

      {contractSelectModal.isOpen && (
        <ContractSelectModal
          onCanceled={() => setContractSelectModal({ isOpen: false })}
          onAdded={(data) => {
            console.log("data :>> ", data);
            queryParamsNavigate({
              ...queryParams,
              contractId: data[0].contractId.toString(),
              contractApplyNumber: data[0].contractApplyNumber,
              contractStep: data[0].contractStep,
            });
            setContractSelectModal({ isOpen: false });
          }}
          defaultCheckedContractList={[{ contractApplyNumber: queryParams?.contractApplyNumber!, contractId: Number(queryParams.contractId) }]}
          contractStepFilter={INVITEABLE_CONTRACT_STEP}
        />
      )}
    </div>
  );
};

export default VisitorList;
