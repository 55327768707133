import { ProductModel } from "src/api/product/product-types";
import { numberToStringWithComma } from "src/utils";
import { SpaceType, SpaceTypeT } from "../../product-types";
import { isNumber } from "lodash";

/**
 *
 * @param type "USED" | "TOTAL"
 * @param time 이용한도 시간
 * @param isUnLimit 이용한도 사용/미사용 false = 제한 없음(무제한),
 * @param totalTime
 * @returns
 */
export const renderTime = (type: "USED" | "TOTAL", time: number | undefined | null, isUnLimit: boolean, totalTime?: number) => {
  let hour: string = "";
  let minute: string | number = "";

  if (!isNumber(time)) {
    return <p>-</p>;
  }

  if (time === -1) {
    time = 0;
  }
  if (totalTime === -1) {
    totalTime = 0;
  }

  hour = numberToStringWithComma(Number(Math.floor(Number(time) / 60)));
  minute = Number(Math.floor(Number(time) % 60));

  if (type === "USED") {
    if (!isUnLimit) {
      return <p>무제한</p>;
    }

    if (minute === 0) {
      return (
        <p>
          <b>{hour}</b>시간
        </p>
      );
    }

    return (
      <p>
        <b>{hour}</b>시간<b>{minute}</b>분
      </p>
    );
  }

  if (type === "TOTAL") {
    if (totalTime === undefined) {
      throw Error("totalTime이 없습니다.");
    }

    const limitTimeString = () => {
      let returnString = "";

      if (!isUnLimit) {
        returnString = "무제한";
      } else {
        returnString = `${hour !== "0" ? hour + "시간" : ""}${minute !== 0 ? minute + "분" : ""}`;
      }

      return returnString;
    };

    const usedTimeString = () => {
      const usedHour = numberToStringWithComma(Number(Math.floor(Number(totalTime) / 60)));
      const usedMinute = Number(Math.floor(Number(totalTime) % 60));

      // console.log("totalTime :>> ", totalTime);
      let returnString = "";

      if (totalTime === 0) {
        // 사용시간이 없으면 미이용 리
        returnString = "미이용";
      } else if (!isUnLimit) {
        //
        //무제한이면 사용시간 리턴
        if (usedMinute === 0) {
          returnString = usedHour + "시간";
        } else {
          returnString = usedHour + "시간" + usedMinute + "분";
        }
        //
      } else {
        //사용시간이 있고 무제한이 아니면 초과시간을 리턴
        let overTime: number = Number(totalTime) - Number(time);
        let overTimeHour = numberToStringWithComma(Number(Math.floor(Number(overTime) / 60)));
        let overTimeMinute = Number(Math.floor(Number(overTime) % 60));

        if (overTime > 1) {
          returnString = `${usedHour !== "0" ? usedHour + "시간" : ""}${usedMinute !== 0 ? usedMinute + "분" : ""}(초과: ${
            overTimeHour !== "0" ? overTimeHour + "시간" : ""
          }${overTimeMinute !== 0 ? overTimeMinute + "분" : ""}
          )`;
        } else {
          returnString = `${usedHour !== "0" ? usedHour + "시간" : ""}${usedMinute !== 0 ? usedMinute + "분" : ""}`;
        }
      }

      return returnString;
    };

    return (
      <p>
        <span>{usedTimeString()}</span>
        <span>{` / `}</span>
        {/* 이용한도 */}
        <span>{limitTimeString()}</span>
      </p>
    );
  }
};

/** 사용중인 공용공간을 공간타입별로 테이블 데이터 형식에 맞게 리턴 해주는 함수 */
export const makeUsedSpaceTableData = (product: ProductModel, spaceType: SpaceTypeT) => {
  const tableData = product.buildingList
    ?.map((data) => {
      if (spaceType === SpaceType.MEETING_ROOM) {
        return data.buildingCommonFacility?.meetingRoomList?.map((space) => {
          return { ...space, buildingName: data.buildingName };
        });
      }

      if (spaceType === SpaceType.REFRESH_ROOM) {
        return data.buildingCommonFacility?.refreshRoomList?.map((space) => {
          return { ...space, buildingName: data.buildingName };
        });
      }

      if (spaceType === SpaceType.DESK) {
        return data.buildingCommonFacility?.deskSpace?.deskGroupList
          ?.map((deskGroup) =>
            deskGroup.deskList?.map((desk) => {
              return { ...desk, groupName: deskGroup.groupName, buildingName: data.buildingName };
            }),
          )
          .flat();
      }
    })
    .flat();
  return tableData;
};

export const MaxReservTimeMonthMap = {
  MEETING_ROOM: "meetingRoomMaxReservTimeMonth",
  DESK: "deskMaxReservTimeMonth",
  REFRESH_ROOM: "refreshRoomMaxReservTimeMonth",
} as const;

export const MaxReservTimeDayMap = {
  MEETING_ROOM: "meetingRoomMaxReservTimeDay",
  DESK: "deskMaxReservTimeDay",
  REFRESH_ROOM: "refreshRoomMaxReservTimeDay",
} as const;
