import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { BaseButton, BaseTooltip } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import useApiLoading from "src/hooks/useApiLoading";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import pagePath from "src/pagePath.json";
import ScheduleInfoColumns from "src/pages/billing/columns/ScheduleInfoColumns";
import StopModal from "src/pages/billing/modal/StopModal";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat } from "src/utils";
import OverdueInfo from "./OverdueInfo";
import S2Columns from "src/pages/billing/columns/S2Columns";
import { s2DisabledEdit } from "src/api/contract/contract-types";
import { getScheduleRoundNOverdueList } from "src/api/billingSchedule/billingSchedule-api";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";

const ScheduleInfo = ({ basicInfo, callList, contractBaseInfo, roundList, overdueList: ol, stopObj, editable, scheduleId }: any) => {
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList);
  const [tableData, setTableData] = useState<any>(roundList);
  const [overdueList, setOverdueList] = useState<any>(ol);
  const { isAuthority } = usePartnerAuthority();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { openToast } = useToast();
  const { isApiLoading } = useApiLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [messageModal, setMessageModal] = useState<Modal>({
    isOpen: false,
  });
  const onSubmit = (e?: any) => {
    setIsOpen(false);
    callList(Number(scheduleId));
  };

  const getRounds = useCallback(
    async (scheduleId: string) => {
      if (scheduleId) {
        setTableData([]);
        setOverdueList([]);
        const rounds = await getRoundList({ scheduleId: Number(scheduleId) });
        console.log("rounds", rounds);
        if (rounds && rounds.status >= 200 && rounds.status <= 299) {
          const sorted: any = rounds.data.data.content;

          const normal_unsorted = sorted.filter((sort: any) => {
            if (sort.bill.isOverdueBill === false) {
              return sort;
            }
          });
          const overdues_unsorted = sorted.filter((sort: any) => {
            if (sort.bill.isOverdueBill) {
              return sort;
            }
          });

          let normal = _.sortBy(normal_unsorted, ["bill.billIssueDate"]);
          let overdues = _.sortBy(overdues_unsorted, ["bill.billIssueDate"]);
          const ovs: any = [];
          normal.forEach((sh: any) => {
            const find = overdues.find((ov: any) => ov.bill.parentBillId === sh.bill.billId);
            if (find) {
              ovs.push(find);
            }
          });

          await setTableData(normal);
          await setOverdueList(ovs);
        }
      }
    },
    [getRoundList],
  );
  const checkOverdue = useCallback(() => {
    const today = moment().format(ViewYmdFormat.FULL);
    const checkList = _.cloneDeep(roundList);
    const checkList2 = _.cloneDeep(overdueList);
    let flag = false;
    for (let i = 0; i < checkList.length; i++) {
      const check = checkList[i];
      const formatted = moment(check.bill.billDueDate).format(ViewYmdFormat.FULL);

      // console.log("check", check);
      if (moment(formatted).isSameOrBefore(moment(today))) {
        if (check.bill.billPayStatus === "PAYMENT_READY") {
          flag = true;
          break;
        }
      }
    }
    for (let i = 0; i < checkList2.length; i++) {
      const check2 = checkList2[i];
      const formatted = moment(check2.bill.billIssueDate).format(ViewYmdFormat.FULL);

      // console.log("check", check);
      if (moment(formatted).isSameOrBefore(moment(today))) {
        if (check2.bill.billPayStatus === "PAYMENT_READY") {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }, [roundList, overdueList]);

  const openPop = useCallback(() => {
    const result = checkOverdue();
    if (result) {
      openToast({ content: "연체/수납 대기 중인 청구서가 있습니다. 중지처리 전에 수납을 완료 해주세요", isSystemToast: true });
      return;
    } else {
      setIsOpen(true);
    }
  }, [checkOverdue, openToast]);

  const showBtn = useMemo(() => {
    let flag = true;

    if (location.pathname.indexOf("/detail/") > -1) {
      flag = false;
    }

    return flag;
  }, [location]);
  const [cols, setCols] = useState<any>([]);
  useEffect(() => {
    let list = _.cloneDeep(ScheduleInfoColumns);

    if (basicInfo?.product?.productType === "TIME_COURT") {
      list = _.cloneDeep(S2Columns);
    }
    getRounds(scheduleId);

    setCols(list);
  }, [basicInfo, getRounds, scheduleId]);

  // console.log("roundList", roundList, basicInfo);

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable(
    {
      columns: cols,
      data: tableData,
      initialState: { pageSize: 1000 },
      basicInfo,
      contractBaseInfo,
      callList,
      stopObj,
      scheduleId,
      showBtn,
      messageModal,
      setMessageModal,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
  );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article" style={{ maxWidth: "unset" }}>
            <div className="flex-center-between mb10">
              <div className="left-area">
                <BaseSectionTitle title={"이용료 빌링 현황"} />
                <div className="contents-container__sub-title flex-center-start gap10">
                  {basicInfo?.product?.productType !== "TIME_COURT" && (
                    <>
                      <BaseButton
                        title={"빌링스케줄 중지 설정"}
                        className="color-white size-small"
                        disabled={contractBaseInfo?.contract?.contractStep !== "TERMINATE_RECEIVED"}
                        onClick={openPop}
                      />
                      <BaseTooltip
                        contents={""}
                        tooltip={
                          "대기/연체 청구서가 있을 시 빌링스케줄을 중지할 수 없습니다. 계약의 상태가 ‘해지접수’ 상태일 때 빌링 중지 가능합니다."
                        }
                        type={"normal"}
                        className={"tooltip__trigger-icon"}
                      />
                      <p className="font14 ml5">
                        스케줄 상태 :{" "}
                        {stopObj && stopObj?.isContractCancel === true
                          ? "중지 (사유: " +
                            (stopObj?.contractCancelReason !== "" && stopObj?.contractCancelReason !== null
                              ? stopObj?.contractCancelReason
                              : "미입력") +
                            ") " +
                            (stopObj?.contractCancelDate !== null ? moment(stopObj?.contractCancelDate).format(ViewYmdFormat.FULL) : "미입력")
                          : "정상"}
                      </p>
                    </>
                  )}
                  {isOpen && (
                    <StopModal
                      showBtn={true}
                      setIsOpen={setIsOpen}
                      round={roundList.billOrder}
                      scheduleId={scheduleId}
                      totalRound={rows}
                      isOpen={isOpen}
                      onClose={() => setIsOpen(false)}
                      onClick={onSubmit}
                    />
                  )}
                </div>
              </div>
              <div className="right-area"></div>
            </div>
            <div>
              <p className="font14 ml5 mb5">
                프로바이더 : {contractBaseInfo?.provider?.provider.providerName && contractBaseInfo?.provider?.provider.providerName}
              </p>
            </div>
            <div className="flex-center">
              <div>
                <p className="font14 ml5 mb5">팝빌 연동 정보 :</p>
                <p className="font14 ml5 mb5">
                  - 현금영수증 조회 API : {contractBaseInfo?.provider?.providerPopbill.isVerifiedCashbill ? "TRUE" : "FALSE"}
                </p>
                <p className="font14 ml5 mb5">
                  - 세금 계산서 발행 API : {contractBaseInfo?.provider?.providerPopbill.isVerifiedTaxInvoice ? "TRUE" : "FALSE"}
                </p>
              </div>
              <div className="ml20">
                <p className="font14 ml5 mb5">페이레터 연동 정보 :</p>
                <p className="font14 ml5 mb5">
                  - PaymentKey API : {contractBaseInfo?.provider?.providerPayletter.isVerifiedAutoPaymentKey ? "TRUE" : "FALSE"}
                </p>
                <p className="font14 ml5 mb5">
                  - Search Key API : {contractBaseInfo?.provider?.providerPayletter.isVerifiedAutoSearchKey ? "TRUE" : "FALSE"}
                </p>
              </div>
            </div>

            <div className="mt30">
              {roundList !== undefined && (
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header) => {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              {header.render("Header")}
                              {/* <div className="ic_sort"></div> */}
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {rows.map((row: any) => {
                      prepareRow(row);
                      return (
                        <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                    {rows.length === 0 && (
                      <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                        <div className="base-table__td w-100 text-center">
                          <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="footer">
                    {footerGroups.map((footerGroup) => (
                      <div {...footerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {footerGroup.headers.map((column) => (
                          <div {...column.getHeaderProps()} className="base-table__td base-table-footer">
                            {column.render("Footer")}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </article>
          {basicInfo?.product?.productType !== "TIME_COURT" && (
            <OverdueInfo
              basicInfo={basicInfo}
              callList={callList}
              contractBaseInfo={contractBaseInfo}
              roundList={roundList}
              overdueList={overdueList}
              stopObj={stopObj}
              editable={editable}
              scheduleId={scheduleId}
              messageModal={messageModal}
              setMessageModal={setMessageModal}
            />
          )}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area">
          {isAuthority("w") && (
            <BaseButton
              title="수정"
              className="size-large"
              disabled={
                contractBaseInfo?.product?.productType !== "TIME_COURT"
                  ? editable
                  : !s2DisabledEdit.includes(contractBaseInfo?.contract?.contractStep)
              }
              onClick={() => {
                navigate(pagePath.billing.form.replace(":id", String(params.id) + "?tab=schedule"));
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleInfo;
