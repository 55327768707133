import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { BaseButton, BaseRadio, BaseTooltip } from "src/components";
import { useModal } from "src/recoil/modalState/hook";
import SpaceBaseTable from "../components/SpaceBaseTable";

import useGetReservationPolicyClosedPeriod from "src/hooks/product/useGetReservationPolicyClosedPeriod";
import ClosedPeriodRegister from "./ClosedPeriodRegister";

import moment from "moment";
import { Row } from "react-table";
import {
  mutationReservationPolicyClosedPeriodAsync,
  mutationReservationPolicyModeAsync,
  ReservationPolicyClosedPeriod,
  ReservationPolicyMode,
} from "src/api/product/product-api";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "src/recoil/toast/hook";
import { axiosInstance, ViewYmdFormat } from "src/utils";
import ClosedPeriodEdit from "./ClosedPeriodEdit";
import { ReservationProps } from "./ReservationAvailabilityPeriod";
import { useEffect, useState } from "react";
import useGetReservationPolicyMode from "src/hooks/product/useGetReservationPolicyMode";

const dayOrder = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

export const ClosedPeriodColumns = [
  {
    Header: "휴무 기간",
    accessor: "startDate",
    width: 220,
    Cell: ({ row, serviceId, serviceType }: { row: Row<ReservationPolicyClosedPeriod>; serviceId: string; serviceType: UnionServiceType }) => {
      return (
        <div>
          {row.original.startDate ? moment(row.original.startDate).format(ViewYmdFormat.YYYY_MM_DD) : ""} -{" "}
          {row.original.endDate ? moment(row.original.endDate).format(ViewYmdFormat.YYYY_MM_DD) : ""}
        </div>
      );
    },
  },
  {
    Header: "휴무 요일",
    accessor: "days",
    width: 300,
    Cell: ({ row, serviceId, serviceType }: { row: Row<ReservationPolicyClosedPeriod>; serviceId: string; serviceType: UnionServiceType }) => {
      const days = row.original?.days
        ?.split(",")
        .sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b))
        .map((day: any) => DayOfWeekTypeLabel[day as keyof typeof DayOfWeekTypeLabel])
        .join(",");

      return <div>{days}</div>;
    },
  },
  {
    Header: "휴무 시간",
    accessor: "endTime",
    width: 200,
    Cell: ({ row, serviceId, serviceType }: { row: Row<ReservationPolicyClosedPeriod>; serviceId: string; serviceType: UnionServiceType }) => {
      return (
        <div>
          {row.original.startTime ? row.original.startTime : ""} - {row.original.endTime ? row.original.endTime : ""}
        </div>
      );
    },
  },
  {
    Header: "수정/삭제",
    accessor: "edit",
    width: 200,
    Cell: ({
      row,
      serviceId,
      serviceType,
      isPolicyMode,
      isDefault,
      space,
      isEditable,
      isRemovable,
    }: {
      row: Row<ReservationPolicyClosedPeriod>;
      serviceId: string;
      serviceType: UnionServiceType;
      isDefault: boolean;
      isPolicyMode?: boolean;
      space?: any;
      isEditable: boolean;
      isRemovable: boolean;
    }) => {
      const { setAbstractModalZ2, setBaseModal } = useModal();
      const queryClient = useQueryClient();
      const { openToast } = useToast();
      const { mutate } = useMutation({
        mutationFn: (data: ReservationPolicyClosedPeriod[]) => mutationReservationPolicyClosedPeriodAsync(axiosInstance, data),
      });

      return (
        <div className="flex-row flex-center">
          {isEditable && (
            <BaseButton
              title={"수정"}
              className="color-white mr4"
              disabled={isPolicyMode}
              onClick={() =>
                setAbstractModalZ2({
                  isOpen: true,
                  size: "xlarge",
                  children: (
                    <ClosedPeriodEdit
                      reservationPolicyClosedPeriodProps={row.original}
                      serviceId={serviceId}
                      serviceType={serviceType}
                      spaceType={row.original?.commonFacilityType!}
                      space={space}
                      isDefault={isDefault}
                    ></ClosedPeriodEdit>
                  ),
                })
              }
            />
          )}
          {isRemovable && (
            <button
              className="base-trash-btn color-bg-gray h50 w50"
              onClick={() => {
                if (isPolicyMode) return;

                setBaseModal({
                  isOpen: true,
                  btnLeftTitle: "취소",
                  btnRightTitle: "확인",
                  title: `휴무일을 삭제하시겠습니까?`,
                  onClick: () => {
                    mutate(
                      [
                        {
                          id: row?.original?.id,
                          serviceId: Number(serviceId),
                          serviceType: serviceType,
                          cmdType: "D",
                        },
                      ],
                      {
                        onSuccess: () => {
                          openToast({ content: `삭제하였습니다.` });
                          queryClient.refetchQueries({ queryKey: ["getReservationPolicyClosedPeriodAsync"] });
                          setBaseModal({ isOpen: false });
                        },
                      },
                    );
                  },
                });
              }}
            ></button>
          )}
          {!isEditable && !isRemovable && "-"}
        </div>
      );
    },
  },
];

export const DAYS = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"] as const;

export const DayOfWeekTypeLabel = {
  MON: "월",
  TUE: "화",
  WED: "수",
  THU: "목",
  FRI: "금",
  SAT: "토",
  SUN: "일",
} as const;

const ClosedPeriod = ({
  spaceType,
  serviceType,
  serviceId,
  isDefault,
  space,
  isEditable,
  isRemovable,
  isChangeValue,
  setIsChangeValue,
}: ReservationProps) => {
  const { setBaseModal, setAbstractModalZ1, setAbstractModalZ2 } = useModal();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (data: ReservationPolicyMode[]) => mutationReservationPolicyModeAsync(axiosInstance, data),
    onSuccess: (data) => {
      queryClient.refetchQueries({
        queryKey: ["getReservationPolicyClosedPeriodAsync"],
      });
    },
  });

  const { data: reservationPolicyModeList } = useGetReservationPolicyMode({
    reservationPolicyModeListParams: {
      serviceType,
      serviceId,
      commonFacilityType: spaceType,
      buildingCommonFacilityId: space?.id?.toString(),
      reservationPolicyModeType: "RESERVATION_POLICY_MODE_CLOSED_PERIOD",
    },
  });

  const { data: closedPeriodList } = useGetReservationPolicyClosedPeriod({
    reservationPolicyClosedPeriodListParams: {
      isDefault: isDefault,
      serviceId: serviceId,
      serviceType: serviceType,
      commonFacilityType: spaceType,
      buildingCommonFacilityId: space?.id,
    },
  });

  const reservationPolicyMode = reservationPolicyModeList?.find(
    (data) =>
      String(data.buildingCommonFacilityId) === String(space?.id) && data.reservationPolicyModeType === "RESERVATION_POLICY_MODE_CLOSED_PERIOD",
  );

  const [isPolicyMode, setIsPolicyMode] = useState<boolean>();

  useEffect(() => {
    const reservationPolicyMode = reservationPolicyModeList?.find(
      (data) =>
        String(data.buildingCommonFacilityId) === String(space?.id) && data.reservationPolicyModeType === "RESERVATION_POLICY_MODE_CLOSED_PERIOD",
    );

    // props isDefault가 false 인개별 설정 페이지에서 reservationPolicyMode의 isDefault 값으로 PolicyMode 값을 사용함(null 또는 undefined 면 true로 전환)
    setIsPolicyMode(!isDefault ? reservationPolicyMode?.isDefault !== false : false);
  }, [isDefault, reservationPolicyMode, reservationPolicyModeList, setIsPolicyMode, space?.id]);

  const { openToast } = useToast();
  return (
    <>
      <section className="base-abstract-modal__contents-minmax-height overflow-hidden px30">
        {!isDefault && (
          <>
            <div className="base-abstract-modal__contents-subtitle">
              <span className="required font18 font-weight-semibold">예약 정책 적용 방식</span>
            </div>
            <div className="flex-center h36">
              <BaseRadio
                id={"isPolicyMode_true"}
                name={"isPolicyMode"}
                checked={isPolicyMode === true}
                disabled={!isEditable}
                label="기본설정"
                onChange={() => {
                  setBaseModal({
                    isOpen: true,
                    title: "선택한 예약 정책이 바로 저장됩니다",
                    btnLeftTitle: "취소",
                    btnRightTitle: "확인",
                    onClick: () => {
                      mutate(
                        [
                          {
                            serviceId: Number(serviceId), // 서비스ID
                            serviceType: serviceType, // 서비스타입
                            commonFacilityType: spaceType, // 공용공간타입
                            isDefault: true, // 기본설정여부
                            buildingCommonFacilityId: Number(space?.id), // 건물-공용공간 ID (isDefault=false인 경우 필수)
                            reservationPolicyModeType: "RESERVATION_POLICY_MODE_CLOSED_PERIOD",

                            cmdType: reservationPolicyMode?.id ? "U" : "C", // 요청타입
                            ...(reservationPolicyMode?.id && {
                              // 수정모드일때 id 등록
                              id: reservationPolicyMode?.id, // 예약가능시간대 ID
                            }),
                          },
                        ],
                        {
                          onSuccess(data, variables, context) {
                            if (data?.data?.data?.content && data?.data?.data?.content?.length > 0) {
                              queryClient.refetchQueries({ queryKey: ["getReservationPolicyModeAsync"] }).then(() => {
                                setIsPolicyMode(true);
                                setBaseModal({ isOpen: false });
                                openToast({ content: "저장 되었습니다.", isSystemToast: true });
                              });
                            }
                          },
                        },
                      );
                    },
                  });
                }}
              />
              <BaseTooltip
                className={"mr10 no-wrap"}
                tooltip={
                  (
                    <div>
                      <div>• 예약 기본 정책 설정을 그대로 따릅니다.</div>
                      <div>• 기본 설정이 변경되면 즉시 적용됩니다.</div>
                    </div>
                  ) as any as string
                }
                touchIcon="QUESTION"
              ></BaseTooltip>
              <BaseRadio
                id={"isPolicyMode_false"}
                name={"isPolicyMode"}
                checked={isPolicyMode === false}
                label="개별설정" // 무제한
                disabled={!isEditable}
                onChange={() => {
                  setBaseModal({
                    isOpen: true,
                    title: "선택한 예약 정책이 바로 저장됩니다",
                    btnLeftTitle: "취소",
                    btnRightTitle: "확인",
                    onClick: () => {
                      mutate(
                        [
                          {
                            serviceId: Number(serviceId), // 서비스ID
                            serviceType: serviceType, // 서비스타입
                            commonFacilityType: spaceType, // 공용공간타입
                            isDefault: false, // 기본설정여부
                            buildingCommonFacilityId: Number(space?.id), // 건물-공용공간 ID (isDefault=false인 경우 필수)
                            reservationPolicyModeType: "RESERVATION_POLICY_MODE_CLOSED_PERIOD",

                            cmdType: reservationPolicyMode?.id ? "U" : "C", // 요청타입
                            ...(reservationPolicyMode?.id && {
                              // 수정모드일때 id 등록
                              id: reservationPolicyMode?.id, // 예약가능시간대 ID
                            }),
                          },
                        ],
                        {
                          onSuccess(data, variables, context) {
                            if (data?.data?.data?.content && data?.data?.data?.content?.length > 0) {
                              queryClient.refetchQueries({ queryKey: ["getReservationPolicyModeAsync"] }).then(() => {
                                setIsPolicyMode(false);
                                setBaseModal({ isOpen: false });
                                openToast({ content: "저장 되었습니다.", isSystemToast: true });
                              });
                            }
                          },
                        },
                      );
                    },
                  });
                }}
              />
              <BaseTooltip
                tooltip={
                  (
                    <div>
                      <div>• 예약 기본 정책 설정을 따르지 않고 이 공용 공간에만 적용되는 별도의 예약 정책을 설정합니다.</div>
                      <div>• 기본 설정이 변경되더라도 영향을 받지 않습니다.</div>
                    </div>
                  ) as any as string
                }
                touchIcon="QUESTION"
              ></BaseTooltip>
            </div>
          </>
        )}
        <div className="base-abstract-modal__contents-subtitle">
          <span className="font18 font-weight-semibold">휴무일</span>
          {isEditable && (
            <BaseButton
              title={"+ 휴무일 등록"}
              disabled={isPolicyMode}
              onClick={() => {
                setAbstractModalZ2({
                  size: "xlarge",
                  isOpen: true,
                  children: (
                    <ClosedPeriodRegister
                      spaceType={spaceType}
                      serviceId={serviceId}
                      serviceType={serviceType}
                      isPolicyMode={isPolicyMode}
                      isDefault={isDefault}
                      setIsChangeValue={setIsChangeValue}
                      space={space}
                    />
                  ),
                });
              }}
            />
          )}
        </div>

        <section className="base-abstract-modal__contents h400 pt10">
          <SpaceBaseTable
            columns={ClosedPeriodColumns}
            data={closedPeriodList ?? []}
            isPolicyMode={isPolicyMode}
            isDefault={isDefault}
            space={space}
            isEditable={isEditable}
            isRemovable={isRemovable}
          ></SpaceBaseTable>
        </section>
      </section>

      <section className="base-abstract-modal__btn-wrap">
        {/* {checkChange && <span className="font14 mr10 text-red">*수정된 사항이 있으니 다시 한번 확인해주세요.</span>} */}
        <BaseButton
          title={"취소"}
          className="color-white"
          onClick={() => {
            setAbstractModalZ1({ isOpen: false });
          }}
        />
      </section>
    </>
  );
};

export default ClosedPeriod;
