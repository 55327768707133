import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { BaseButton, BaseCheckbox, BaseRadio, BaseTooltip } from "src/components";
import { BaseInputNumberFormat } from "src/components/BaseInputNumberFormat";
import { SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { commaizePrice, deleteCommas, showHourMinute } from "src/utils";
import { EditObj } from "./SpaceUsageLimitModal";
import { decommaizeNumber } from "@toss/utils";

const LimitFrom = ({
  spaceType,
  editObj,
  setEditObj,
  onClose,
  handleSubmit,
  isEditable,
  setIsChangeValue,
}: {
  spaceType: SpaceTypeT;
  editObj: EditObj;
  setEditObj: Dispatch<SetStateAction<EditObj>>;
  onClose: () => void;
  handleSubmit: () => void;
  isEditable?: boolean;
  setIsChangeValue: any;
}) => {
  const partner = useRecoilValue(globalPartnerState);

  /** 파트너의 공용공간 예약의 후불정산 기능 화성 여부  */
  const isPostPaymentReservation = partner?.features?.filter((PaPartner) => PaPartner.name === "FEATURE_RESERVATION_POST_PAYMENT")?.[0]?.active;
  console.log(`isEditable`, isEditable);
  const [errors, setErrors] = useState({
    isUnLimitReservation: "",
    isMonthlyUnLimit: "",
    monthlyTime: "",
    isDailyUnLimit: "",
    dailyTime: "",
    isAllowOverReservation: "",
  });

  const { setBaseModal } = useModal();
  const renderText = (isLimit: boolean, time: string) => {
    if (isLimit) {
      return time === "" || time === "0" ? "분 (0시간 0분)" : `분 (${showHourMinute(deleteCommas(time))})`;
    } else {
      return;
    }
  };

  const onValid = () => {
    //월
    if (editObj.isMonthlyUnLimit && Number(editObj.monthlyTime) <= 0) {
      return setErrors((prev) => ({ ...prev, monthlyTime: "이용한도를 입력해주세요." }));
    } else if ((decommaizeNumber(editObj.monthlyTime.toString()) % 60) % 30 !== 0) {
      //30분 단위
      return setErrors((prev) => ({ ...prev, monthlyTime: "30분 단위로 입력해주세요" }));
    } else {
      //초기화
      setErrors((prev) => ({ ...prev, monthlyTime: "" }));
    }

    //일
    if (editObj.isDailyUnLimit && Number(editObj.dailyTime) <= 0) {
      return setErrors((prev) => ({ ...prev, dailyTime: "이용한도를 입력해주세요." }));
    } else if ((decommaizeNumber(editObj.dailyTime.toString()) % 60) % 30 !== 0) {
      //30분단위
      return setErrors((prev) => ({ ...prev, dailyTime: "30분 단위로 입력해주세요" }));
    } else if (
      editObj.isDailyUnLimit &&
      editObj.isMonthlyUnLimit &&
      decommaizeNumber(editObj.monthlyTime.toString()) < decommaizeNumber(editObj.dailyTime.toString())
    ) {
      return setErrors((prev) => ({ ...prev, dailyTime: "월 이용 한도 내에서 입력해주세요." }));
    } else {
      //초기화
      setErrors((prev) => ({ ...prev, dailyTime: "" }));
    }

    if (!editObj.isUnLimitReservation) {
      if (!editObj.isDailyUnLimit && !editObj.isMonthlyUnLimit) {
        return setBaseModal({
          isOpen: true,
          btnRightTitle: "확인",
          title: "월 이용한도 또는 일 이용한도를 설정해주세요",
        });
      }
    }
    setBaseModal({
      isOpen: true,
      btnRightTitle: "확인",
      btnLeftTitle: "취소",
      title: "저장하시겠습니까?",
      children: "저장 후에는 변경 사항이 즉시 적용됩니다.",
      onClick: () => {
        handleSubmit();
        setBaseModal({ isOpen: false });
      },
    });
  };

  return (
    <>
      <section className="base-abstract-modal__contents px30">
        <div className="pt10">
          <ul className="base-list">
            <li className="font14">기본 정책 설정: 전체 공용공간의 예약 사용량을 제한하는 목적으로 이용한도를 설정할 수 있습니다.</li>
            <li className="font14">개별 정책 설정: 각 공간별로 예약 사용량을 제한하는 목적으로 이용한도를 설정할 수 있습니다.</li>
            <li className="font14">
              비용 부과: 기본 정책 설정과 개별 정책 설정에서 초과 예약을 허용하고 후불 정산을 설정한 경우, 전체 이용한도를 기준으로 초과 사용량에 대해
              비용이 발생할 수 있습니다.
            </li>
          </ul>
        </div>
        <section className="base-abstract-modal__contents-subtitle">
          <h2 className="required">이용한도 제한</h2>
        </section>
        <div className="flex-center pt10">
          <div className="flex-center minmax220">
            <BaseRadio
              id={spaceType + "_isLimited_True"}
              name={spaceType + "_isLimited_True"}
              checked={editObj.isUnLimitReservation === true}
              label="무제한" // 무제한
              onChange={() => {
                setEditObj((prev) => ({
                  ...prev,
                  isUnLimitReservation: true,
                  isMonthlyUnLimit: false,
                  isDailyUnLimit: false,
                }));
                if (setIsChangeValue) {
                  setIsChangeValue(true);
                }
              }}
              className={"mr10"}
            />
            <BaseRadio
              id={spaceType + "_isLimited_False"}
              name={spaceType + "_isLimited_False"}
              checked={!editObj.isUnLimitReservation}
              label="제한"
              onChange={() => {
                setEditObj((prev) => ({ ...prev, isUnLimitReservation: false, isMonthlyUnLimit: true, isDailyUnLimit: true }));
                if (setIsChangeValue) {
                  setIsChangeValue(true);
                }
              }}
            />
          </div>
        </div>

        <section className="base-abstract-modal__contents-subtitle">
          <h2>월 이용한도</h2>
        </section>
        <div>
          <div className="flex-center pt10">
            <div className="flex-center">
              <BaseCheckbox
                className="w-fit mr10"
                id={spaceType + "month"}
                name={spaceType + "month"}
                checked={editObj.isMonthlyUnLimit}
                disabled={editObj.isUnLimitReservation}
                onChange={(value) => {
                  setEditObj((prev) => ({
                    ...prev,
                    isMonthlyUnLimit: value,
                  }));
                  if (setIsChangeValue) {
                    setIsChangeValue(true);
                  }
                }}
                label="적용함"
              />
              <div className="minmax240">
                <BaseInputNumberFormat
                  //2,147,483,647
                  type="text"
                  value={commaizePrice(editObj.monthlyTime)}
                  onChange={(text: string) => {
                    setEditObj((prev) => ({ ...prev, monthlyTime: commaizePrice(text) }));
                    if (setIsChangeValue) {
                      setIsChangeValue(true);
                    }
                  }}
                  disable={editObj.isUnLimitReservation || !editObj.isMonthlyUnLimit}
                />
              </div>
            </div>
            <span className="font18 ml10">{renderText(!editObj.isUnLimitReservation, editObj.monthlyTime.toString()) ?? "분(0시간 0분)"}</span>
          </div>
          {errors.monthlyTime && <p className="validation-text">{errors.monthlyTime}</p>}
        </div>
        <section className="base-abstract-modal__contents-subtitle">
          <h2>일 이용한도</h2>
        </section>
        <div>
          <div className="flex-center pt10">
            <div className="flex-center">
              <BaseCheckbox
                className="w-fit mr10"
                id={spaceType + "daily"}
                name={spaceType + "daily"}
                checked={editObj.isDailyUnLimit}
                disabled={editObj.isUnLimitReservation}
                onChange={(value) => {
                  setEditObj((prev) => ({
                    ...prev,
                    isDailyUnLimit: value,
                  }));
                  if (setIsChangeValue) {
                    setIsChangeValue(true);
                  }
                }}
                label="적용함"
              />
              <div className="minmax240">
                <BaseInputNumberFormat
                  type="text"
                  value={commaizePrice(editObj.dailyTime)}
                  onChange={(text: string) => {
                    setEditObj((prev) => ({ ...prev, dailyTime: commaizePrice(text) }));
                    if (setIsChangeValue) {
                      setIsChangeValue(true);
                    }
                  }}
                  disable={editObj.isUnLimitReservation || !editObj.isDailyUnLimit}
                />
              </div>
            </div>
            <span className="font18 ml10">{renderText(!editObj.isUnLimitReservation, editObj.dailyTime.toString()) ?? "분(0시간 0분)"}</span>
          </div>
          {errors.dailyTime && <p className="validation-text">{errors.dailyTime}</p>}
        </div>

        {isPostPaymentReservation && editObj.isAllowOverReservation !== null && (
          <>
            <section className="base-abstract-modal__contents-subtitle">
              <h2 className="d-flex flex-center">
                이용한도 초과되는 예약 허용 여부
                <BaseTooltip
                  contents={""}
                  tooltip={
                    "이용한도 초과된 이용시간의 집계는 월 사용시간 확인 팝업에서 확인하실 수 있으며, 공용공간 요금 설정 시 초과 요금도 집계됩니다."
                  }
                  type={"normal"}
                  className={"tooltip__trigger-icon"}
                />
              </h2>
            </section>
            <div className="flex-center pt10">
              <div className="flex-center minmax220">
                <BaseRadio
                  className="mr10"
                  id={spaceType + "_overReservation_false"}
                  checked={
                    editObj.isAllowOverReservation === null || editObj.isAllowOverReservation === undefined ? false : !editObj.isAllowOverReservation
                  }
                  onChange={() => {
                    setEditObj((prev) => ({ ...prev, isAllowOverReservation: false }));
                    if (setIsChangeValue) {
                      setIsChangeValue(true);
                    }
                  }}
                  name={spaceType + "_overReservation_false"}
                  label="비허용"
                  disabled={editObj.isUnLimitReservation}
                />
                <BaseRadio
                  id={spaceType + "_overReservation_true"}
                  checked={
                    editObj.isAllowOverReservation === null || editObj.isAllowOverReservation === undefined ? false : editObj.isAllowOverReservation
                  }
                  onChange={() => {
                    setEditObj((prev) => ({ ...prev, isAllowOverReservation: true }));
                    if (setIsChangeValue) {
                      setIsChangeValue(true);
                    }
                  }}
                  name={spaceType + "_overReservation_true"}
                  label="허용하고 후불정산"
                  disabled={editObj.isUnLimitReservation}
                />
              </div>
            </div>
          </>
        )}
      </section>
      <section className="base-abstract-modal__btn-wrap">
        {/* {checkChange && <span className="font14 mr10 text-red">*수정된 사항이 있으니 다시 한번 확인해주세요.</span>} */}
        <BaseButton title={"취소"} className="color-white" onClick={onClose} />
        {isEditable && <BaseButton title={"저장"} onClick={() => onValid()} />}
      </section>
    </>
  );
};

export default LimitFrom;
