import { decommaizeNumber } from "@toss/utils";
import { useState } from "react";
import { ContractFacility, DetailTotalData } from "src/api/contract/contract-types";
import usePostOperationFacility from "src/hooks/contract/usePostOperationFacility";
import { ContractFacilityTableData } from "src/pages/contract/commonComponents/reservation/components/ContractSpaceContents";
import LimitFrom from "src/pages/product/forms/commonSpaceForm/modal/LimitFrom";
import { SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";

interface Props {
  space: ContractFacilityTableData;
  isChangeValue?: boolean;
  setIsChangeValue?: React.Dispatch<React.SetStateAction<boolean>>;
  isEditable: boolean;
  detailData: DetailTotalData;
}

/** 신청계약 요금,이용한도 설정 모달 */
const ContractSpaceUsageLimitModal = ({ space, detailData, isChangeValue, setIsChangeValue, isEditable }: Props) => {
  const { setAbstractModalZ1 } = useModal();
  const { setBaseModal } = useModal();
  const spaceType = space.commonFacilityType as SpaceTypeT;
  const { openToast } = useToast();
  const { mutate } = usePostOperationFacility();

  console.log("신청계약 요금,이용한도 설정 모달 - ContractSpaceUsageLimitModal - space :>> ", space);

  const [editObj, setEditObj] = useState({
    //무제한 여부
    isUnLimitReservation: space.isUnlimitedReservation,
    //월 이용한도 체크여부
    isMonthlyUnLimit: space.isLimitedMonthlyReservation,
    //일 이용한도 체크여부
    isDailyUnLimit: space.isLimitedDailyReservation,
    //시간
    monthlyTime: space.monthlyServiceMinutes,
    dailyTime: space.dailyServiceMinutes,
    //초과 예약 여부
    isAllowOverReservation: space.isAllowedOverReservation,
  });

  const handleSubmit = () => {
    //LimitFrom 내부에서 수정된 editObj 로 수정 데이터를 생성
    const commonFacility: ContractFacility = {
      contractFacilityId: space.contractFacilityId, // string - 계약 공용시설 ID (신규 공용시설 추가 시: 계약 공용시설 ID 0 입력)
      id: String(space.id), // string - 건물 공용시설 ID (공간상품 관리)
      // resourceEmail: "example@example.com", // string - 리소스 캘린더 ID
      isUnlimitedReservation: editObj.isUnLimitReservation, // boolean - 예약 무제한 여부
      isAllowedOverReservation: editObj.isAllowOverReservation, // boolean - 초과예약 허용 여부
      isLimitedMonthlyReservation: editObj.isMonthlyUnLimit, // boolean - 월별 예약제한 사용 여부
      monthlyServiceMinutes: editObj.monthlyTime?.toString() === "" ? -1 : decommaizeNumber(editObj.monthlyTime?.toString()!), // integer - 월별 제공시간(분)
      isLimitedDailyReservation: editObj.isDailyUnLimit, // boolean - 일별 예약제한 사용 여부
      dailyServiceMinutes: editObj.dailyTime?.toString() === "" ? -1 : decommaizeNumber(editObj.dailyTime?.toString()!), // integer - 일별 예약제한 시간(분)
    };

    //공용공간 생성/수정 함수 RCA37. 계약 - 운영정보 공용시설 수정 API 사용
    mutate(
      { contractId: Number(detailData.contract?.contractId), productFacilityList: [commonFacility] },
      { onSuccess: () => openToast({ content: "저장 되었습니다.", isSystemToast: true }) },
    );
  };

  return (
    <LimitFrom
      spaceType={spaceType}
      editObj={editObj as any}
      setEditObj={setEditObj as any}
      onClose={() => {
        setAbstractModalZ1({ isOpen: false });
      }}
      handleSubmit={() => handleSubmit()}
      setIsChangeValue={setIsChangeValue}
      isEditable={isEditable}
    />
  );
};
export default ContractSpaceUsageLimitModal;
