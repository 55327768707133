import { useEffect } from "react";

/**
 *
 * 테이블을 아래와 같이 base-table-scroll-warp 클래스로 감싸주세요
 * <div className="base-table-scroll-warp">
 *   <table ref={tableRef}></table>
 * </div>
 * @param tableRef
 */
const useScrollShadow = (tableRef: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    if (tableRef.current) {
      const parentNode = tableRef.current.parentNode;

      if (parentNode instanceof Element && parentNode !== null) {
        // 부모 노드 내에 이미 존재하는 base-table-scroll-warp 확인
        const existingWrapper = parentNode.classList.contains("base-table-scroll-warp");

        if (!existingWrapper) {
          // 감싸는 div 생성
          const wrapperDiv = document.createElement("div");
          wrapperDiv.className = "base-table-scroll-warp"; // 클래스 설정

          // wrapperDiv를 tableRef의 부모 노드에 추가
          parentNode.insertBefore(wrapperDiv, tableRef.current); // wrapperDiv를 tableRef의 앞에 추가
          wrapperDiv.appendChild(tableRef.current); // tableRef를 wrapperDiv의 자식으로 이동
        }
      }
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const { scrollLeft, clientWidth, scrollWidth } = tableRef.current;

        // 왼쪽 스크롤 상태 확인
        if (scrollLeft > 0) {
          tableRef.current.classList.add("left-shadow");
        } else {
          tableRef.current.classList.remove("left-shadow");
        }

        // 오른쪽 스크롤 상태 확인
        if (scrollLeft + clientWidth < scrollWidth) {
          tableRef.current.classList.add("right-shadow");
        } else {
          tableRef.current.classList.remove("right-shadow");
        }

        // ref 컨테이너 좌표
        const containerRect = tableRef.current.getBoundingClientRect();

        // 스티키 엘리먼트 찾기
        const leftStickyElement = tableRef.current.querySelector('[data-sticky-last-left-td="true"]');
        const rightStickyElement = tableRef.current.querySelector('[data-sticky-first-right-td="true"]');

        if (leftStickyElement) {
          const leftStickyRect = leftStickyElement.getBoundingClientRect();
          const relativeLeft = leftStickyRect.right - containerRect.left;
          tableRef.current.style.setProperty("--left-shadow", `${relativeLeft}px`);
        }

        if (rightStickyElement) {
          const rightStickyRect = rightStickyElement.getBoundingClientRect();
          const relativeRight = containerRect.right - rightStickyRect.left;
          tableRef.current.style.setProperty("--right-shadow", `${relativeRight}px`);
        }
      }
    };

    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener("scroll", handleScroll);
      handleScroll(); // 초기 상태 확인
    }

    return () => {
      if (tableElement) {
        tableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef]); // tableRef만 의존성으로 추가
};

export default useScrollShadow;
