import { useState } from "react";
import { SpaceType as ConstSpaceType, SpaceTypeT } from "src/pages/product/product-types";

import { decommaizeNumber } from "@toss/utils";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import useGetProduct from "src/hooks/product/useGetProduct";
import useUpdateProduct from "src/hooks/product/useUpdateProduct";
import { useModal } from "src/recoil/modalState/hook";
import LimitFrom from "./LimitFrom";
import { useToast } from "src/recoil/toast/hook";

export const SpaceTypeReqDataMap = {
  [ConstSpaceType.MEETING_ROOM]: {
    isUnLimitReservation: "isUnlimitedMeetingRoom",
    isMonthlyUnLimit: "isLimitedMeetingRoomMonthlyReservation",
    monthlyTime: "meetingRoomMaxReservTimeMonth",
    isDailyUnLimit: "isLimitedMeetingRoomDailyReservation",
    dailyTime: "meetingRoomMaxReservTimeDay",
    isAllowOverReservation: "isAllowedMeetingRoomOverReservation",
    contractTotalTime: "meetingRoomTotalTime", // TotalTime을 월별 예약 가능 시간으로 사용
    contractDailyTime: "meetingRoomDailyTime",
  },
  [ConstSpaceType.DESK]: {
    isUnLimitReservation: "isUnlimitedDesk",
    isMonthlyUnLimit: "isLimitedDeskMonthlyReservation",
    monthlyTime: "deskMaxReservTimeMonth",
    isDailyUnLimit: "isLimitedDeskDailyReservation",
    dailyTime: "deskMaxReservTimeDay",
    isAllowOverReservation: "isAllowedDeskOverReservation",
    contractTotalTime: "deskTotalTime",
    contractDailyTime: "deskDailyTime",
  },
  [ConstSpaceType.REFRESH_ROOM]: {
    isUnLimitReservation: "isUnlimitedRefreshRoom",
    isMonthlyUnLimit: "isLimitedRefreshRoomMonthlyReservation",
    monthlyTime: "refreshRoomMaxReservTimeMonth",
    isDailyUnLimit: "isLimitedRefreshRoomDailyReservation",
    dailyTime: "refreshRoomMaxReservTimeDay",
    isAllowOverReservation: "isAllowedRefreshRoomOverReservation",
    contractTotalTime: "refreshRoomTotalTime",
    contractDailyTime: "refreshRoomDailyTime",
  },
} as const;

interface Props {
  spaceType: SpaceTypeT;
  serviceType: UnionServiceType;
  serviceId: string;
  isEditable?: boolean;
  isChangeValue?: boolean;
  setIsChangeValue?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AllSpaceUsageLimitModal = ({ spaceType, serviceType, serviceId, isEditable, isChangeValue, setIsChangeValue }: Props) => {
  const { data: product } = useGetProduct({ productId: serviceId });
  const { setBaseModal } = useModal();
  const { mutate } = useUpdateProduct();
  const { setAbstractModalZ1 } = useModal();
  const { openToast } = useToast();
  const [editObj, setEditObj] = useState({
    //무제한 여부
    isUnLimitReservation: product?.[SpaceTypeReqDataMap[spaceType]?.isUnLimitReservation],
    //월 이용한도 체크여부
    isMonthlyUnLimit: product?.[SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit],
    monthlyTime: product?.[SpaceTypeReqDataMap[spaceType].monthlyTime] === -1 ? "" : product?.[SpaceTypeReqDataMap[spaceType].monthlyTime] ?? "",
    //일 이용한도 체크여부
    isDailyUnLimit: product?.[SpaceTypeReqDataMap[spaceType].isDailyUnLimit],
    dailyTime: product?.[SpaceTypeReqDataMap[spaceType].dailyTime] === -1 ? "" : product?.[SpaceTypeReqDataMap[spaceType].dailyTime] ?? "",
    //초과 예약 여부
    isAllowOverReservation: product?.[SpaceTypeReqDataMap[spaceType].isAllowOverReservation],
  });

  const handleSubmit = async () => {
    const data = {
      [SpaceTypeReqDataMap[spaceType].isUnLimitReservation]: editObj.isUnLimitReservation,
      [SpaceTypeReqDataMap[spaceType].isMonthlyUnLimit]: editObj.isMonthlyUnLimit,
      [SpaceTypeReqDataMap[spaceType].monthlyTime]: editObj.monthlyTime.toString() === "" ? -1 : decommaizeNumber(editObj.monthlyTime.toString()),
      [SpaceTypeReqDataMap[spaceType].isDailyUnLimit]: editObj.isDailyUnLimit,
      [SpaceTypeReqDataMap[spaceType].dailyTime]: editObj.dailyTime.toString() === "" ? -1 : decommaizeNumber(editObj.dailyTime.toString()),
      [SpaceTypeReqDataMap[spaceType].isAllowOverReservation]: editObj.isAllowOverReservation,
    };

    mutate({ id: product?.id!, ...data }, { onSuccess: () => openToast({ content: "저장 되었습니다.", isSystemToast: true }) });
  };

  return (
    <>
      <LimitFrom
        spaceType={spaceType}
        editObj={editObj as any}
        setEditObj={setEditObj as any}
        onClose={() => {
          setAbstractModalZ1({ isOpen: false });
        }}
        handleSubmit={handleSubmit}
        setIsChangeValue={setIsChangeValue}
        isEditable={isEditable}
      />
    </>
  );
};

export default AllSpaceUsageLimitModal;
